

import { useState, useEffect } from 'react';
// @mui
import { Stack, Button, Typography, Box, Grid, Menu, MenuItem, IconButton, TextField } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import { WhatsApp, Email } from '@mui/icons-material';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// sections
import AddDocument from './Add';
import Edit from './Edit';
import { apiget, deleteManyApi } from '../../service/api';
import TableStyleTwo from '../../components/TableStyleTwo';
import DeleteModel from '../../components/Deletemodle';

// ----------------------------------------------------------------------
const userRole = localStorage.getItem('userRole');

const FamilyTree = ({ rows, toggleVisibilityContact, isVisibleContact, setUserAction, _id }) => {

    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [contactId, setContactId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [familyTreeData, setFamilyTreeData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [shareData, setShareData] = useState(null);
    const [searchText, setSearchText] = useState(''); // Search input state
    const [filteredRows, setFilteredRows] = useState(rows); // Filtered rows state
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setShareData(row); // Save the row data for sharing
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const fetchFamilyEdit = async (rowId) => {
        setContactId(rowId);
        const result = await apiget(`contact-relation/view/${rowId}`);
        setFamilyTreeData(result?.data);
    };

    const deleteMany = async (deleteId) => {
        const result = await deleteManyApi('contact-relation/deletemany', deleteId);
        if (result && result.status === 200) {
            setUserAction(result);
            handleCloseDelete();
        }
    };

    useEffect(() => {
        if (searchText.trim() === '') {
            // Create a copy of rows before sorting to avoid modifying the original array
            const sortedData = [...(rows ?? [])].sort((a, b) => {
                const nameA = a?.firstName?.toLowerCase() || '';
                const nameB = b?.firstName?.toLowerCase() || '';

                return nameA.localeCompare(nameB);
            });

            setFilteredRows(sortedData); // Set the sorted data to filteredRows
        } else {
            const filteredData = rows && rows.filter((row) => {
                setLoading(true);

                return (
                    row.firstName?.toLowerCase().startsWith(searchText.toLowerCase()) ||
                    row.lastName?.toLowerCase().startsWith(searchText.toLowerCase())
                );
            });

            setFilteredRows(filteredData);
            setLoading(false);
        }
    }, [searchText, rows]);



    const handleShareWhatsApp = () => {
        const message = `
Name: ${shareData?.firstName} ${shareData?.lastName}
DOB: ${shareData?.dateOfBirth}
Phone: ${shareData?.phoneNumber}
Email: ${shareData?.emailAddress}
Aadhar Card: ${shareData?.aadhar}
PAN Card: ${shareData?.pan}
Relation: ${shareData?.relation}
        `;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
        handleCloseMenu();
    };

    const handleShareEmail = () => {
        const subject = 'Family Member Info';
        const body = `
Name: ${shareData?.firstName} ${shareData?.lastName}
DOB: ${shareData?.dateOfBirth}
Phone: ${shareData?.phoneNumber}
Email: ${shareData?.emailAddress}
Aadhar Card: ${shareData?.aadhar}
PAN Card: ${shareData?.pan}
Relation: ${shareData?.relation}
        `;
        const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoUrl;
        handleCloseMenu();
    };

    const columns = [
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            minWidth: 100,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {
                const firstName = () => {
                    fetchFamilyEdit(params.row._id);
                    handleOpenEdit();
                };
                return (
                    <Box onClick={firstName}>
                        {params.value}
                    </Box>
                );
            }
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            minWidth: 100
        },
        {
            field: "gender",
            headerName: "Gender",
            flex: 1,
            minWidth: 100
        },
        {
            field: "emailAddress",
            headerName: "Email Address",
            flex: 1,
            minWidth: 150
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            flex: 1,
            minWidth: 150
        },
        {
            field: "relation",
            headerName: "Relation",
            flex: 1,
            minWidth: 100
        },
        {
            field: "pan",
            headerName: "PAN Number",
            flex: 1,
            minWidth: 150
        },
        {
            field: "aadhar",
            headerName: "Aadhar Number",
            flex: 1,
            minWidth: 150
        },
        {
            field: "dateOfBirth",
            headerName: "DOB",
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;

                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }

                return '--';
            },
        }
        ,
        {
            field: "dateOfAnniversary",
            headerName: "Anniversary Date",
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;

                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }

                return '--';
            },
        },
        userRole === 'admin' && {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {

                const handleDeleteFamiyMember = (Id) => {
                    setSelectedId(Id);
                    handleOpenDelete();
                }

                return (
                    <Stack direction={"row"} key={params.row._id} alignItems={'center'} spacing={2} justifyContent={'center'}>
                        <DeleteIcon
                            variant='outlined'
                            size='small'
                            sx={{ cursor: 'pointer' }}
                            color='error'
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteFamiyMember(params.row._id);
                            }}
                        />

                        <EditIcon
                            variant='outlined'
                            size='small'
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={(e) => {
                                e.preventDefault();
                                fetchFamilyEdit(params.row._id);
                                handleOpenEdit();
                            }}
                        />
                        <IconButton onClick={(e) => handleClick(e, params.row)}>
                            <MoreVertIcon />
                        </IconButton>

                        {/* Menu for share options */}
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <MenuItem onClick={handleShareWhatsApp}>
                                <WhatsApp sx={{ color: '#25D366' }} />
                            </MenuItem>
                            <MenuItem onClick={handleShareEmail}>
                                <Email sx={{ color: '#BB001B' }} />
                            </MenuItem>
                        </Menu>
                    </Stack>
                );
            }
        },
    ];

    return (
        <>
            {/* Add Document Model */}
            <DeleteModel opendelete={openDelete} handleClosedelete={handleCloseDelete} deletedata={deleteMany} id={[selectedId]} />
            <AddDocument open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />
            <Edit open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} rowId={contactId} data={familyTreeData} fetchFamilyEdit={fetchFamilyEdit} />

            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityContact}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleContact ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">Family Tree</Typography>
                            {isVisibleContact && <TextField
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                style={{ marginBottom: '10px' }}
                            />}
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleContact && userRole === 'admin' && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Add Family
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {isVisibleContact && (
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={row => row._id}
                            loading={loading}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10, page: 0 } },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                        />
                    </Box>
                </TableStyleTwo>
            )}
        </>
    );
};

export default FamilyTree;
