
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Container, TextField, Typography, Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@mui/x-data-grid';
import { apiget } from "../../service/api";
import TableStyle from '../../components/TableStyle';

const userRole = localStorage.getItem("userRole");
const userDataString = localStorage.getItem('user');
const userData = JSON.parse(userDataString);
const email = userData?.emailAddress || '';

// Custom Toolbar Component
function CustomToolbar({ searchText, setSearchText, fetchdata }) {
    const inputRef = useRef(null); // Create a ref for the input

    // Handle search on input change
    const onEnter = (e) => {
        const value = e.target.value;
        setSearchText(value); // Keep the input value updated in the parent component
        fetchdata(value); // Call the API to fetch the filtered data
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Keep the input focused after state updates
        }
    }, [searchText]); // Run this effect whenever searchText changes

    return (
        <GridToolbarContainer style={{ display: 'flex', alignItems: 'center' }}>
            <GridToolbar />
            {userRole === 'admin' && (
                <TextField
                    inputRef={inputRef} // Assign the ref to the TextField
                    value={searchText} // Controlled input using the state from the parent
                    onChange={onEnter} // Handle search as user types
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: '10px' }}
                />
            )}
        </GridToolbarContainer>
    );
}

// Main Component
const RecievedPayments = () => {
    const [contactList, setContactList] = useState([]);
    const [userContact, setUserContact] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [loading, setLoading] = useState(false); // Track loading state
    const [searchText, setSearchText] = useState(''); // State to keep track of search input

    const navigate = useNavigate();

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    // Fetch data initially and handle search
    useEffect(() => {
        fetchdata();
        checkContactByEmail();
    }, []); // Empty dependency array to fetch data on mount

    // Fetch data based on search input
    const fetchdata = async (searchText = '') => {
        setLoading(true); // Start loading
        try {
            const result = await apiget(`contact/list?search=${searchText}`);
            if (result && result.status === 200) {
                const sortedData = result?.data?.result.sort((a, b) => {
                    const nameA = a.firstName || '';
                    const nameB = b.firstName || '';
                    return nameA.localeCompare(nameB);
                });
                setContactList(sortedData); // Update contact list based on search
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false); // Stop loading after API call completes
    };

    const checkContactByEmail = async () => {
        const result = await apiget(`contact/user-contact?email=${email}`);
        if (result && result.status === 200) {
            setUserContact(result?.data?.result); // Fetch user-specific contacts
        }
    };

    const columns = [
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {
                const handleFirstNameClick = () => {
                    navigate(`/dashboard/recieved-payment/view/${params.id}`);
                };

                return (
                    <Box onClick={handleFirstNameClick}>
                        {params.value}
                    </Box>
                );
            }
        },
        { field: "lastName", headerName: "Last Name", flex: 1 },
        { field: "gender", headerName: "Gender", flex: 1 },
        { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
        { field: "emailAddress", headerName: "Email Address", flex: 1 },
    ];

    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4">
                    {userRole === 'admin' ? "Received Payments" : 'Policy Payments'}
                </Typography>
            </Stack>
            <TableStyle>
                <Box width="100%">
                    <Card style={{ height: "600px", paddingTop: "15px" }}>
                        <DataGrid
                            rows={userRole === 'admin' ? contactList : userContact}
                            columns={columns}
                            components={{ Toolbar: () => <CustomToolbar searchText={searchText} setSearchText={setSearchText} fetchdata={fetchdata} /> }}
                            checkboxSelection
                            onRowSelectionModelChange={handleSelectionChange}
                            rowSelectionModel={selectedRowIds}
                            getRowId={(row) => row._id}
                            loading={loading}
                        />
                    </Card>
                </Box>
            </TableStyle>
        </Container>
    );
};

export default RecievedPayments;
