/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, InputLabel } from "@mui/material";
import { toast } from "react-toastify";
import { apipost, apiget, apiput } from "../../service/api";
// import { FiSave } from "react-icons/fi";
// import { GiCancel } from "react-icons/gi";
import Palette from "../../theme/palette";

const Edit = (props) => {

    const { open, handleClose, setUserAction, rowId, data, fetchFamilyEdit } = props
    const userid = localStorage.getItem('user_id');
    const [relationToTheInsured, setRelationToTheInsured] = useState([]);


    // -----------  validationSchema
    const validationSchema = yup.object({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string(),
        gender: yup.string(),
        phoneNumber: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid'),
        emailAddress: yup.string().email('Invalid email'),
        relation: yup.string(),
        others: yup.string(),
        pan: yup
            .string()
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'invalid PAN '),
        aadhar: yup
            .string()
            .matches(/^\d{12}$/, 'invalid aadhar'),
        dateOfBirth: yup.date(),
        dateOfAnniversary: yup.date(),

    });


    // -----------   initialValues
    const initialValues = {
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        gender: data?.gender || '',
        phoneNumber: data?.phoneNumber || '',
        emailAddress: data?.emailAddress || '',
        relation: data?.relation || '',
        others: data?.others || '',
        aadhar: data?.aadhar || '',
        pan: data?.pan || '',
        dateOfBirth: data?.dateOfBirth || '',
        dateOfAnniversary: data?.dateOfAnniversary || '',

    };
    const [showOtherField, setShowOtherField] = useState(data?.relation === "Others");


    const handleRelationChange = (event) => {
        const selectedRelation = event.target.value;
        if (selectedRelation === "Others") {
            setShowOtherField(true);
        } else {
            setShowOtherField(false);
            formik.setFieldValue("others", "");
        }
        formik.handleChange(event);
    };


    const editContact = async (values) => {

        const result = await apiput(`contact-relation/edit/${rowId}`, values)
        setUserAction(result)
        if (result && result.status === 200) {

            handleClose();
            formik.resetForm();
            fetchFamilyEdit(rowId);
        }
    }




    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {

            const contactData = {
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                phoneNumber: values.phoneNumber,
                emailAddress: values.emailAddress,
                relation: values.relation,
                others: values.others,
                aadhar: values.aadhar || '',
                pan: values.pan || '',
                dateOfBirth: values.dateOfBirth || '',
                dateOfAnniversary: values.dateOfAnniversary || '',
            }

            editContact(contactData)
        },
    });


    const fetchRelationShip = async () => {
        const result = await apiget('relationship/list');

        if (result && result.status === 200) {
            const sortedRelationShip = result?.data?.result.sort((a, b) => a.order - b.order);
            setRelationToTheInsured(sortedRelationShip)
        }
    }

    useEffect(() => {
        fetchRelationShip();
    }, [])




    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // backgroundColor: "#2b4054",
                        // color: "white",
                    }}
                >
                    <Typography variant="h6">Edit</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <Typography style={{ marginBottom: "15px" }} variant="h6">
                            Basic Information
                        </Typography>
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>First name</FormLabel>
                                <TextField
                                    id="firstName"
                                    name="firstName"
                                    size="small"

                                    maxRows={10}
                                    fullWidth
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.firstName &&
                                        Boolean(formik.errors.firstName)
                                    }
                                    helperText={
                                        formik.touched.firstName && formik.errors.firstName
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Last name</FormLabel>
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    size="small"
                                    fullWidth
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={
                                        formik.touched.lastName && formik.errors.lastName
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Date Of Birth</FormLabel>
                                <TextField
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    size="small"
                                    type="date"
                                    fullWidth
                                    value={formik.values.dateOfBirth}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                                    helperText={
                                        formik.touched.dateOfBirth && formik.errors.dateOfBirth
                                    }
                                    InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Date Of Anniversary</FormLabel>
                                <TextField
                                    id="dateOfAnniversary"
                                    name="dateOfAnniversary"
                                    size="small"
                                    type="date"
                                    fullWidth
                                    value={formik.values.dateOfAnniversary}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dateOfAnniversary && Boolean(formik.errors.dateOfAnniversary)}
                                    helperText={
                                        formik.touched.dateOfAnniversary && formik.errors.dateOfAnniversary
                                    }
                                    InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Aadhar</FormLabel>
                                <TextField aadhar
                                    id="aadhar"
                                    name='aadhar'
                                    size='small'
                                    type='number'
                                    fullWidth
                                    value={formik.values.aadhar}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 12) {
                                            formik.setFieldValue('aadhar', value);
                                        }
                                    }}
                                    error={
                                        formik.touched.aadhar &&
                                        Boolean(formik.errors.aadhar)
                                    }
                                    helpertext={formik.touched.aadhar && formik.errors.aadhar}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>PAN Card</FormLabel>
                                <TextField
                                    id="pan"
                                    name='pan'
                                    size='small'
                                    type='text'
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    value={formik.values.pan}
                                    onChange={(e) => {
                                        const value = e.target.value.toUpperCase();
                                        if (value.length <= 10 && /^[A-Z]{0,5}[0-9]{0,4}[A-Z]{0,1}$/.test(value)) {
                                            formik.setFieldValue('pan', value);
                                        }
                                    }}
                                    error={
                                        formik.touched.pan &&
                                        Boolean(formik.errors.pan)
                                    }
                                    helpertext={formik.touched.pan && formik.errors.pan}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Phone number</FormLabel>
                                <TextField
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    size="small"
                                    type="number"
                                    fullWidth
                                    value={formik.values.phoneNumber}
                                    onChange={(e) => {
                                        const value = e.target.value;
                    
                                        // Allow only numeric values and limit to 10 digits
                                        if (/^\d{0,10}$/.test(value)) {
                                          formik.setFieldValue('phoneNumber', value);
                                        }
                                      }}
                                    error={
                                        formik.touched.phoneNumber &&
                                        Boolean(formik.errors.phoneNumber)
                                    }
                                    helperText={
                                        formik.touched.phoneNumber && formik.errors.phoneNumber
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Email</FormLabel>
                                <TextField
                                    id="emailAddress"
                                    name="emailAddress"
                                    size="small"
                                    fullWidth
                                    value={formik.values.emailAddress}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.emailAddress &&
                                        Boolean(formik.errors.emailAddress)
                                    }
                                    helperText={
                                        formik.touched.emailAddress && formik.errors.emailAddress
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Relation</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="relation"
                                        name="relation"
                                        size="small"
                                        fullWidth
                                        value={formik.values.relation}
                                        onChange={handleRelationChange}
                                        error={formik.touched.relation && Boolean(formik.errors.relation)}
                                    >
                                        {relationToTheInsured && relationToTheInsured.map((option, index) => (
                                            <MenuItem key={index} value={option.relation}>
                                                {option.relation}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText style={{ color: Palette.error.main }}>
                                        {formik.touched.relation && formik.errors.relation}
                                    </FormHelperText>
                                </FormControl>
                                {showOtherField && (
                                    <FormControl fullWidth style={{ marginTop: '20px' }}  >
                                        <TextField
                                            id="others"
                                            name="others"
                                            label="others"
                                            value={formik.values.others}
                                            onChange={formik.handleChange}
                                            variant="outlined"
                                            size="small"
                                            error={formik.touched.others && Boolean(formik.errors.others)}
                                            helperText={formik.touched.others && formik.errors.others}
                                        />
                                    </FormControl>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Gender</FormLabel>
                                    <RadioGroup row name="gender" onChange={formik.handleChange} value={formik.values.gender}>
                                        <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="Female" control={<Radio />} label="Female" />

                                    </RadioGroup>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.gender && formik.errors.gender}</FormHelperText>
                                </FormControl>
                            </Grid>

                        </Grid>



                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Edit