/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { apiget, apiput } from "../../service/api";
import Palette from "../../theme/palette";


const Edit = (props) => {

  const { open, handleClose, id, fetchContact } = props
  const [contactData, setContactData] = useState({});
  const [referralSource,setReferralSource]=useState([]);



  // -----------  validationSchema
  const validationSchema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string(),
    dateOfBirth: yup.date().nullable(),
    dateOfAnniversary: yup.date().nullable(),
    gender: yup.string(),
    phoneNumber: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid'),
    emailAddress: yup.string().email('Invalid email'),
    address: yup.string(),
    alternatePhoneNumber: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid'),
    referralPhoneNumber: yup.string().matches(/^[0-9]{10}$/, 'Phone number is invalid'),
    additionalEmailAddress: yup.string().email('Invalid email'),
    pan: yup
      .string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'invalid PAN '),
    aadhar: yup
      .string()
      .matches(/^\d{12}$/, 'invalid aadhar')
  });

  // -----------   initialValues
  const initialValues = {
    firstName: contactData?.firstName,
    lastName: contactData?.lastName,
    dateOfBirth: contactData?.dateOfBirth,
    dateOfAnniversary: contactData?.dateOfAnniversary,
    gender: contactData?.gender,
    phoneNumber: contactData?.phoneNumber,
    emailAddress: contactData?.emailAddress,
    address: contactData?.address,
    alternatePhoneNumber: contactData?.alternatePhoneNumber,
    additionalEmailAddress: contactData?.additionalEmailAddress,
    // instagramProfile: contactData?.instagramProfile,
    // twitterProfile: contactData?.twitterProfile,
    preferredContactMethod: contactData?.preferredContactMethod,
    referralSource: contactData?.referralSource,
    referralContactName: contactData?.referralContactName,
    relationshipToReferrer: contactData?.relationshipToReferrer,
    referralPhoneNumber: contactData?.referralPhoneNumber,
    preferencesForMarketingCommunications: contactData?.preferencesForMarketingCommunications,
    preferredLanguage: contactData?.preferredLanguage,
    modifiedOn: "",
    pan: contactData?.pan,
    aadhar: contactData?.aadhar,
  };

  // add Contact Edit api
  const editContact = async (values) => {
    const data = values;
    const result = await apiput(`contact/edit/${id}`, data)

    if (result && result.status === 200) {
      handleClose();
      fetchContact();
    }
  }

  // fetch api
  const fetchdata = async () => {
    const result = await apiget(`contact/view/${id}`)
    if (result && result.status === 200) {
      setContactData(result?.data[0])
    }
  }

  const fetchReferralData = async () => {
    try {
      const result = await apiget('referral-source/list');
      if (result && result.status === 200) {

        const sourceType = result.data.result.filter(item => item.sourceType.toLowerCase().includes('contact'));
        setReferralSource(sourceType);
      }
    } catch (error) {
      console.error('Error fetching referral data:', error);
    }
  }

  useEffect(() => {
    fetchReferralData();
  }, [])

  useEffect(() => {
    if (id) {
      fetchdata();
    }

  }, [open])


  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const ContactData = {
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: values.dateOfBirth,
        dateOfAnniversary: values.dateOfAnniversary,
        gender: values.gender,
        phoneNumber: values.phoneNumber,
        emailAddress: values.emailAddress,
        address: values.address,
        alternatePhoneNumber: values.alternatePhoneNumber,
        additionalEmailAddress: values.additionalEmailAddress,
        // instagramProfile: values.instagramProfile,
        // twitterProfile: values.twitterProfile,
        preferredContactMethod: values.preferredContactMethod,
        referralSource: values.referralSource,
        referralContactName: values.referralContactName,
        relationshipToReferrer: values.relationshipToReferrer,
        referralPhoneNumber: values.referralPhoneNumber,
        preferencesForMarketingCommunications: values.preferencesForMarketingCommunications,
        preferredLanguage: values.preferredLanguage,
        modifiedOn: new Date(),
        pan: values.pan,
        aadhar: values.aadhar,
      }
      editContact(ContactData)

    },
  });

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            // backgroundColor: "#2b4054",
            // color: "white",
          }}
        >
          <Typography variant="h6">Edit</Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <form>
            <Typography style={{ marginBottom: "15px" }} variant="h6">
              Basic Information
            </Typography>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 0, sm: 5, md: 4 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>First name<Typography component={'span'} color={'error'} >*</Typography></FormLabel>
                <TextField
                  id="firstName"
                  name="firstName"
                  size="small"
                  maxRows={10}
                  fullWidth
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName &&
                    Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Last name</FormLabel>
                <TextField
                  id="lastName"
                  name="lastName"
                  size="small"
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={
                    formik.touched.lastName && formik.errors.lastName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Date Of Birth</FormLabel>
                <TextField
                  name='dateOfBirth'
                  type='date'
                  size='small'
                  fullWidth
                  value={dayjs(formik.values.dateOfBirth).format('YYYY-MM-DD')}
                  onChange={formik.handleChange}
                  error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                  helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
                  InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Date Of Anniversary</FormLabel>
                <TextField
                  name='dateOfAnniversary'
                  type='date'
                  size='small'
                  fullWidth
                  value={dayjs(formik.values.dateOfAnniversary).format('YYYY-MM-DD')}
                  onChange={formik.handleChange}
                  error={formik.touched.dateOfAnniversary && Boolean(formik.errors.dateOfAnniversary)}
                  helperText={formik.touched.dateOfAnniversary && formik.errors.dateOfAnniversary}
                  InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Phone number</FormLabel>
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  size="small"
                  type="number"
                  fullWidth
                  value={formik.values.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numeric values and limit to 10 digits
                    if (/^\d{0,10}$/.test(value)) {
                      formik.setFieldValue('phoneNumber', value);
                    }
                  }}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }

                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Email</FormLabel>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  size="small"
                  fullWidth

                  value={formik.values.emailAddress} s
                  onChange={formik.handleChange}
                  error={
                    formik.touched.emailAddress &&
                    Boolean(formik.errors.emailAddress)
                  }
                  helperText={
                    formik.touched.emailAddress && formik.errors.emailAddress
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Aadhar</FormLabel>
                <TextField aadhar
                  id="aadhar"
                  name='aadhar'
                  size='small'
                  type='number'
                  fullWidth
                  value={formik.values.aadhar}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 12) {
                      formik.setFieldValue('aadhar', value);
                    }
                  }}
                  error={
                    formik.touched.aadhar &&
                    Boolean(formik.errors.aadhar)
                  }
                  helpertext={formik.touched.aadhar && formik.errors.aadhar}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>PAN Card</FormLabel>
                <TextField
                  id="pan"
                  name='pan'
                  size='small'
                  type='text'
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  value={formik.values.pan}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    if (value.length <= 10 && /^[A-Z]{0,5}[0-9]{0,4}[A-Z]{0,1}$/.test(value)) {
                      formik.setFieldValue('pan', value);
                    }
                  }}
                  error={
                    formik.touched.pan &&
                    Boolean(formik.errors.pan)
                  }
                  helpertext={formik.touched.pan && formik.errors.pan}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Gender</FormLabel>
                  <RadioGroup
                    row
                    name="gender"
                    onChange={formik.handleChange}
                    value={formik.values.gender || ''} // Ensure it's controlled
                  >
                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                  <FormHelperText style={{ color: Palette.error.main }}>
                    {formik.touched.gender && formik.errors.gender}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>Address</FormLabel>
                <TextField
                  id="address"
                  name="address"
                  size="small"
                  multiline
                  rows={5}
                  fullWidth
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
            </Grid>
            <Typography style={{ marginBottom: "15px" }} variant="h6" mt={2}>
              Additional Contact Details
            </Typography>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 0, sm: 5, md: 4 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Alternate phone number</FormLabel>
                <TextField
                  id="alternatePhoneNumber"
                  name="alternatePhoneNumber"
                  type="number"
                  size="small"
                  fullWidth
                  value={formik.values.alternatePhoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.alternatePhoneNumber && Boolean(formik.errors.alternatePhoneNumber)
                  }
                  helperText={formik.touched.alternatePhoneNumber && formik.errors.alternatePhoneNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Additional email address</FormLabel>
                <TextField
                  id="additionalEmailAddress"
                  name="additionalEmailAddress"
                  type="email"
                  size="small"
                  fullWidth
                  value={formik.values.additionalEmailAddress}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.additionalEmailAddress && Boolean(formik.errors.additionalEmailAddress)
                  }
                  helperText={formik.touched.additionalEmailAddress && formik.errors.additionalEmailAddress}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Instagram profile</FormLabel>
                <TextField
                  id="instagramProfile"
                  name="instagramProfile"
                  type=""
                  size="small"
                  fullWidth
                  value={formik.values.instagramProfile}
                  onChange={(e) => formik.setFieldValue('instagramProfile', `${e.target.value}`)}
                />
                {formik.values.instagramProfile && <a href={`https://www.instagram.com/${formik.values.instagramProfile}`} target="_blank" rel="noreferrer">Link</a>}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Twitter profile</FormLabel>
                <TextField
                  id="twitterProfile"
                  name="twitterProfile"
                  type=""
                  size="small"
                  fullWidth
                  value={formik.values.twitterProfile}
                  onChange={(e) => formik.setFieldValue('twitterProfile', `${e.target.value}`)}
                />
                {formik.values.twitterProfile && <a href={`https://twitter.com/${formik.values.twitterProfile}`} target="_blank" rel="noreferrer">Link</a>}
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <FormLabel>Preferred Contact Method</FormLabel>
                <TextField
                  id="preferredContactMethod"
                  name="preferredContactMethod"
                  type=""
                  size="small"
                  fullWidth
                  value={formik.values.preferredContactMethod}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Typography style={{ marginBottom: "15px" }} variant="h6" mt={2}>
              Referral Information
            </Typography>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 0, sm: 5, md: 4 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel>Referral source</FormLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="referralSource"
                    name="referralSource"
                    size="small"
                    value={formik.values.referralSource}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.referralSource && Boolean(formik.errors.referralSource)
                    }
                  >
                    {
                      referralSource && referralSource.map((item, index) => (
                        <MenuItem key={index} value={item.referralSource}>{item.referralSource}</MenuItem>

                      ))
                    }
                  </Select>
                  <FormHelperText
                    error={
                      formik.touched.referralSource && Boolean(formik.errors.referralSource)
                    }
                  >
                    {formik.touched.referralSource && formik.errors.referralSource}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Referral Contact Name</FormLabel>
                <TextField
                  id="referralContactName"
                  name="referralContactName"
                  size="small"
                  fullWidth
                  value={formik.values.referralContactName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Relationship To Referrer</FormLabel>
                <TextField
                  id="relationshipToReferrer"
                  name="relationshipToReferrer"
                  size="small"
                  fullWidth
                  value={formik.values.relationshipToReferrer}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel htmlFor="referralPhoneNumber">Referral Phone number</FormLabel>
                <TextField
                  id="referralPhoneNumber"
                  name="referralPhoneNumber"
                  size="small"
                  type="text" // Use "text" instead of "number" to allow for formatting
                  fullWidth
                  value={formik.values.referralPhoneNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {
                      formik.setFieldValue('referralPhoneNumber', value);
                    }
                  }}
                  error={formik.touched.referralPhoneNumber && Boolean(formik.errors.referralPhoneNumber)}
                  helperText={formik.touched.referralPhoneNumber && formik.errors.referralPhoneNumber}
                />
              </Grid>
            </Grid>
            <Typography style={{ marginBottom: "15px" }} variant="h6" mt={2}>
              Communication Preferences
            </Typography>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 0, sm: 5, md: 4 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <FormLabel>Marketing Communications</FormLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="preferencesForMarketingCommunications"
                    name="preferencesForMarketingCommunications"
                    size="small"
                    value={formik.values.preferencesForMarketingCommunications}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.preferencesForMarketingCommunications && Boolean(formik.errors.preferencesForMarketingCommunications)
                    }
                  >
                    <MenuItem value="Opt-in">Opt-in</MenuItem>
                    <MenuItem value="Opt-out">Opt-out</MenuItem>
                  </Select>
                  <FormHelperText
                    error={
                      formik.touched.preferencesForMarketingCommunications && Boolean(formik.errors.preferencesForMarketingCommunications)
                    }
                  >
                    {formik.touched.preferencesForMarketingCommunications && formik.errors.preferencesForMarketingCommunications}
                  </FormHelperText>
                </FormControl>

              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormLabel>Preferred language</FormLabel>
                <TextField
                  id="preferredLanguage"
                  name="preferredLanguage"
                  type=""
                  size="small"
                  fullWidth
                  value={formik.values.preferredLanguage}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
            style={{ textTransform: "capitalize" }}
          >
            Save
          </Button>
          <Button
            type="reset"
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            color="error"
            onClick={() => {
              formik.resetForm()
              handleClose()
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Edit