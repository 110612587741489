/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel } from "@mui/material";
import { apiput } from "../../../service/api";

const Edit = (props) => {
    const { open, handleClose, setUserAction, _id, data } = props
    const userid = localStorage.getItem('user_id')
    const documentId = data._id;
  
    // -----------  validationSchema
    const validationSchema = yup.object({
        file: yup.string(),
        surgeryPersonName: yup.string().required("Person Name is required"),
        surgeryName: yup.string().required("Surgery Name is required"),
        hospitalName: yup.string().required("Hospital Name is required"),
        dateOfSurgery: yup.string().required('Date of Surgery is required')
    });

    // -----------   initialValues
    const initialValues = {
        file: data?.file || '',
        surgeryPersonName: data?.surgeryPersonName || "",
        surgeryName: data?.surgeryName || "",
        hospitalName: data?.hospitalName || "",
        dateOfSurgery: data?.dateOfSurgery || "",
        policy_id: _id,
        createdBy: userid
    };

    // add contact api
    const fileUpload = async (values) => {
     
        const data = new FormData()

        data.append("name", values.file.name);
        data.append("file", values.file);
        data.append("surgeryPersonName", values.surgeryPersonName);
        data.append("surgeryName", values.surgeryName);
        data.append("dateOfSurgery", values.dateOfSurgery);
        data.append("hospitalName", values.hospitalName);
        data.append("policy_id", values.policy_id);
        data.append("createdBy", values.createdBy);

        const result = await apiput(`surgery-documents/edit/${documentId}`, data)

        setUserAction(result)
        if (result && result.status === 200) {
            formik.resetForm();
            handleClose();

        }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values)
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit} >
                        <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 5, md: 4 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Person Name</FormLabel>
                                <TextField
                                    id="surgeryPersonName"
                                    name="surgeryPersonName"
                                    size="small"
                                    fullWidth
                                    value={formik.values.surgeryPersonName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.surgeryPersonName && Boolean(formik.errors.surgeryPersonName)}
                                    helperText={formik.touched.surgeryPersonName && formik.errors.surgeryPersonName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Surgery Name</FormLabel>
                                <TextField
                                    id="surgeryName"
                                    name="surgeryName"
                                    size="small"
                                    fullWidth
                                    value={formik.values.surgeryName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.surgeryName && Boolean(formik.errors.surgeryName)}
                                    helperText={formik.touched.surgeryName && formik.errors.surgeryName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Hospital Name</FormLabel>
                                <TextField
                                    id="hospitalName"
                                    name="hospitalName"
                                    size="small"
                                    fullWidth
                                    value={formik.values.hospitalName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.hospitalName && Boolean(formik.errors.hospitalName)}
                                    helperText={formik.touched.hospitalName && formik.errors.hospitalName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Date of Surgery</FormLabel>
                                <TextField
                                    name="dateOfSurgery"
                                    type="date"
                                    size="small"
                                    fullWidth
                                    value={formik.values.dateOfSurgery}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dateOfSurgery && Boolean(formik.errors.dateOfSurgery)}
                                    helperText={formik.touched.dateOfSurgery && formik.errors.dateOfSurgery}
                                    InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Upload File</FormLabel>
                                <TextField
                                    id="file"
                                    name="file"
                                    size="small"
                                    fullWidth
                                    type="file"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => {
                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                    error={formik.touched.file && Boolean(formik.errors.file)}
                                    helperText={formik.touched.file && formik.errors.file}
                                />
                            </Grid>

                            {data && data?.file && (
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Uploaded File</FormLabel>
                                    <TextField
                                        value={formik.values.file}
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true, // This makes the TextField read-only
                                        }}
                                    />
                                </Grid>
                            )}

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Edit