import PropTypes from 'prop-types';
import { useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
// @mui
import {
  Card,
  Stack,
  CardHeader,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
// components
import { apiput } from '../../../service/api';

// ----------------------------------------------------------------------

AppTasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  allcounts: PropTypes.object.isRequired,
};

export default function AppTasks({ title, allcounts, fetchtasks, list, ...other }) {
  const { control, setValue } = useForm({
    defaultValues: {
      taskCompleted: [], // Initialize the completed task array
    },
  });

  const handleCheckboxChange = async (taskId, isCompleted) => {

    const newStatus = isCompleted ? 'Completed' : 'Not Started';

    const result = await apiput(`task/update-status/${taskId}`, { status: newStatus });

    if (result && result.status === 200) {
      fetchtasks();
    }

  };

  return (
    <Card {...other} sx={{ height: '100%' }}>
      <CardHeader
        title={
          <Stack direction="row" justifyContent="space-between">
            {title}
            <span style={{ fontSize: '0.8rem', color: '#888', display: 'flex', gap: '2vw' }}>
              <span>{`Completed: ${allcounts.completed}`}</span>
              <span>{`Pending: ${allcounts.inProgress}`}</span>
              <span>{`Not Started: ${allcounts.notStarted}`}</span>
            </span>
          </Stack>
        }
      />
      <Controller
        name="taskCompleted"
        control={control}
        
        render={({ field }) => (
          <>
            {list.map((task) => (
              <TaskItem
                key={task._id}
                task={task}
                checked={task.status === 'Completed'}
                onChange={(e) => {
                  handleCheckboxChange(task._id, e.target.checked);
                }}
              />
            ))}
          </>
        )}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  task: PropTypes.shape({
    _id: PropTypes.string,
    subject: PropTypes.string,
    status: PropTypes.string,
  }),
};

function TaskItem({ task, checked, onChange }) {
  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 0.75,
        ...(checked && {
          color: 'text.disabled',
          textDecoration: 'line-through',
        }),
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} />}
        label={task.subject}
        sx={{ flexGrow: 1, m: 0 }}
      />
    </Stack>
  );
}
