import React from 'react';
import { Grid, Card, Box, CardHeader, Typography, List, ListItem, ListItemText } from '@mui/material';

const AppPaymentExpenses = ({ paymentMethods }) => {


    return (
        <Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Typography variant="h6">Expenses</Typography>
                
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List>
                        {paymentMethods && paymentMethods.map((method) => (
                            <ListItem key={method.paymentMethod}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography variant="body1">{method.paymentMethod}</Typography>
                                    <Typography variant="body2" color="textSecondary">{`₹${method.amount}`}</Typography>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Card>
    )
}

export default AppPaymentExpenses