/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
// @mui
import { Stack, Button, Typography, Box, Grid, Switch,TextField } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete"
import ShareIcon from "@mui/icons-material/Share"
import DownloadIcon from "@mui/icons-material/Download"
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from '@mui/icons-material/Edit';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Add from '../policyDocument/Add'
import Edit from '../policyDocument/Edit'
import View from '../../HelpCentreManagement/view'
// sections

import {  deleteManyApi, apiget, apiput } from '../../../service/api';
import TableStyleTwo from '../../../components/TableStyleTwo';
import { constant } from '../../../constant';
import DeleteModel from '../../../components/Deletemodle'
// ----------------------------------------------------------------------

const OldPolicy = ({ toggleVisibilityOldPolicy, fetchdata, isVisibleOldPolicy, userAction, setUserAction, _id }) => {

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  const [oldPolicyData, setOldPolicyData] = useState([]);
  const [policyDocumentData, setPolicyDocumentData] = useState([]);

  const [openView, setOpenView] = useState(false);
  const handleOpenView = () => setOpenView(true);
  const userRole = localStorage.getItem("userRole");

  const [openDelete, setOpenDelete] = useState(false)
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [selectedId,setSelectedId]=useState(null);
  const [searchText, setSearchText] = useState(''); // Search input state
    const [filteredRows, setFilteredRows] = useState([]); // Filtered rows state
    const [loading, setLoading] = useState(false);


  const deleteMany = async (deleteId) => {
    const result = await deleteManyApi('policydocument/deletemany', deleteId)
    if (result && result.status === 200) {
      handleCloseDelete();
      setUserAction(result)
    }
  }

  const fetchOldPolicyData = async () => {
    const result = await apiget(`policydocument/expired-policy/${_id}`)

    const sortedData = result?.data?.policy.sort((a, b) => {
      const nameA = a.fileName || '';
      const nameB = b.fileName || '';
      return nameA.localeCompare(nameB);
    });
    setOldPolicyData(sortedData);

  }

  const downloadFile = async (id) => {
    if (id) {
      const result = await apiget(`policydocument/file/${id}`)
      setUserAction(result)
    }
  }




  const changePolicyStatus = async (id, newStatus) => {

    const data = { status: newStatus };
    try {
      const result = await apiput(`policydocument/expired-policy-document/${id}`, data);
      if (result && result.status === 200) {

        setUserAction(result)
        fetchdata();

      }

    } catch (error) {
      console.error("Error updating note status:", error);
    }

  }

  const EditFileName = async (id) => {
    fetchPolicyDocument(id)
    handleOpenEdit();

  }

  const fetchPolicyDocument = async (id) => {
    const result = await apiget(`policydocument/view/${id}`);
    if (result && result.status === 200) {
      setPolicyDocumentData(result?.data?.result)
      setUserAction(result?.data?.result)
    }
  }

  useEffect(() => {
    fetchOldPolicyData();

  }, [userAction])

  useEffect(() => {
    if (searchText.trim() === '') {
        
        setFilteredRows(oldPolicyData); 
    } else {
        const filteredData = oldPolicyData && oldPolicyData.filter((row) => {
            setLoading(true);

            return (
                row.fileName?.toLowerCase().startsWith(searchText.toLowerCase())
            );
        });

        setFilteredRows(filteredData);
        setLoading(false);
    }
}, [searchText, oldPolicyData]);

  const columns = [
    {
      field: "file",
      headerName: "File",
      flex: 1,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {

        const ViewPolicyDocument = () => {
          fetchPolicyDocument(params.row._id)
          handleOpenView();
        }

        return (
          <Box onClick={ViewPolicyDocument}>
            {params.value}
          </Box>
        )
      }
    },

    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      cellClassName: "name-column--cell--capitalize"
    },
    {
      field: "createdOn",
      headerName: "CreateOn",
      flex: 1,
      valueFormatter: (params) => {
        const value = params.value;
        const date = value ? new Date(value) : null;
        if (date && !Number.isNaN(date.getTime())) {
            return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
        }
        return '--';
    },
    },
    userRole === 'admin' ? {
      field: "status",
      headerName: "Status",
      flex: 0,
      renderCell: (params) => {

        const handleStatusChange = (event) => {
          const newStatus = event.target.checked ? 'expired' : 'active';
          changePolicyStatus(params.row._id, newStatus);
          return newStatus;

        };

        return (
          <Switch
            checked={params.row.expiryStatus === 'expired'}
            onChange={handleStatusChange}
            name="status"
            color="primary"
          />
        );
      }
    } : '',
    {
      field: "action",
      headerName: "Action",
      headerAlign: 'center',
      flex: 1,

      renderCell: (params) => {
        const handleFirstNameClick = async () => { downloadFile(params.row._id) };
        const downloadUrl = `${constant.baseUrl}policydocument/file/${params.row._id}`;

        const handleShareClick = () => {
          const message = `Click this link to Download  Policy Document : ${downloadUrl}`;
          const encodedMessage = encodeURIComponent(message);
          const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
          window.open(whatsappUrl, '_blank');
        };

        const deleteRowData=(id)=>{
          setSelectedId(id);
          handleOpenDelete();
        }

        return (

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Stack direction="row" spacing={2}>
              <a href={downloadUrl} download>
                <DownloadIcon variant="contained" fontSize="small" onClick={handleFirstNameClick} />
              </a>
              <Edit open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} _id={params.row._id} data={policyDocumentData} />
              {userRole === 'admin' && <EditIcon variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary'
                onClick={() => EditFileName(params.row._id)} />}
              {userRole === 'admin' && (
                <DeleteIcon
                  variant="outlined"
                  fontSize="small"
                  color="error"
                  sx={{
                    cursor:'pointer'
                  }}
                  onClick={()=>deleteRowData(params.row._id)}
                />
              )}
              
              <ShareIcon variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary'
                onClick={handleShareClick} />
            </Stack>
          </Box>

        );
      }
    },

  ];

  return (
    <>
    <DeleteModel opendelete={openDelete} handleClosedelete={handleCloseDelete} deletedata={deleteMany} id={[selectedId]} />
      <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={policyDocumentData} header="Old Policy Document" imageFolder="policyDocuments" />
      <Add open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} status={"expired"} />

      <Box p={2} style={{ cursor: "pointer" }}>
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Button
                onClick={toggleVisibilityOldPolicy}
                color="secondary"
                variant="contained"
                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
              >
                {isVisibleOldPolicy ? <RemoveIcon /> : <AddIcon />}
              </Button>
              <AttachmentIcon />
              <Typography variant="h5">Old Policy Documents</Typography>
              {isVisibleOldPolicy && <TextField
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                style={{ marginBottom: '10px' }}
                            />}
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
              {isVisibleOldPolicy && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAdd}
                >
                  Add Document
                </Button>
              )}
            </Stack>

          </Stack>
        </Grid>
      </Box>
      {
        isVisibleOldPolicy &&
        <TableStyleTwo>
          <Box width="100%" height="30vh">
            <DataGrid
              rows={filteredRows}
              columns={columns}
              getRowId={row => row._id}
              columnHeaderHeight={40}
              loading={loading}

            />
          </Box>
        </TableStyleTwo>

      }
    </>
  );
}

export default OldPolicy