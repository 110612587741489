import { Box, Button, Grid, Stack, Typography,TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddEvent from "./AddTask";
import TableStyleTwo from '../TableStyleTwo';

// eslint-disable-next-line react/prop-types
const Tasks = ({ rows, toggleVisibilityTask, isVisibleTask, _id, setUserAction, leadData }) => {
    const [openTask, setOpenTask] = useState(false);
    // open task model
    const handleOpenTask = () => setOpenTask(true);
    const handleCloseTask = () => setOpenTask(false);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState(''); // Search input state
    const [filteredRows, setFilteredRows] = useState(rows); // Filtered rows state
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            field: "subject",
            headerName: "Subject",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {
                const handleFirstNameClick = () => {
                    navigate(`/dashboard/task/view/${params.row._id}`)
                };

                return (
                    <Box onClick={handleFirstNameClick}>
                        {params.value}
                    </Box>
                );
            }
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
        },
        {
            field: "startDate",
            headerName: "Start Date",
            flex: 1,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;
                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }
                return '--';
            },
        },
        {
            field: "endDate",
            headerName: "End Date",
            flex: 1,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;
                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }
                return '--';
            },
        },
        {
            field: "priority",
            headerName: "Priority",
            flex: 1,

        }
    ];



    useEffect(() => {
        if (searchText.trim() === '') {
            // Create a copy of rows before sorting to avoid modifying the original array
            const sortedData = [...(rows ?? [])].sort((a, b) => {
                const nameA = a?.subject?.toLowerCase() || '';
                const nameB = b?.subject?.toLowerCase() || '';

                return nameA.localeCompare(nameB);
            });

            setFilteredRows(sortedData); // Set the sorted data to filteredRows
        } else {
            const filteredData = rows && rows.filter((row) => {
                setLoading(true);

                return (
                    row.subject?.toLowerCase().startsWith(searchText.toLowerCase()) ||
                    row.priority?.toLowerCase().startsWith(searchText.toLowerCase())
                );
            });

            setFilteredRows(filteredData);
            setLoading(false);
        }
    }, [searchText, rows]);


    return (
        <div>
            {/* Add Tasks */}
            <AddEvent open={openTask} handleClose={handleCloseTask} _id={_id} setUserAction={setUserAction} leadDatas={leadData} />

            <Box style={{ cursor: "pointer" }} p={2}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityTask}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleTask ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AssignmentIcon />
                            <Typography variant="h5">Tasks</Typography>
                            {isVisibleTask && <TextField
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                style={{ marginBottom: '10px' }}
                            />}
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleTask && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"

                                    startIcon={<AddIcon />}
                                    onClick={handleOpenTask}
                                >
                                    Create Task
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisibleTask &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                            loading={loading}
                        />
                    </Box>
                </TableStyleTwo>
            }
        </div>
    )
}

export default Tasks
