/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from 'react';
// @mui
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    Box,
    TextField
} from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../../components/iconify';
// sections
// mock
import AddContact from './Add'
import TableStyle from '../../components/TableStyle';
import { apiget, deleteManyApi } from '../../service/api';
import DeleteModel from '../../components/Deletemodle'
import EditContact from './Edit'

// ----------------------------------------------------------------------

function CustomToolbar({ selectedRowIds, searchText, setSearchText, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);
    const inputRef = useRef(null); // Create a ref for the input

    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    const deleteManyContact = async (data) => {
        await deleteManyApi('contact/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    const onEnter = (e) => {
        const value = e.target.value;
        setSearchText(value); // Keep the input value updated in the parent component
        fetchdata(value); // Call the API to fetch the filtered data
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Keep the input focused after state updates
        }
    }, [searchText]);

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />

            <TextField
                inputRef={inputRef} // Assign the ref to the TextField
                value={searchText} // Controlled input using the state from the parent
                onChange={onEnter} // Handle search as user types
                placeholder="Search..."
                variant="outlined"
                size="small"
                style={{ marginBottom: '10px' }}
            />

        </GridToolbarContainer>
    );
}


const Contact = () => {

    const [contactList, setContactList] = useState([]);

    const [referralSource, setReferralSource] = useState([]);
    const [userAction, setUserAction] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState('');
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState(''); // State to keep track of search input

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")

    // open add model
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    // open edit model
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    const columns = [
        {
            field: "firstName",
            headerName: "Frist Name",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {
                const handleFirstNameClick = () => {
                    navigate(`/dashboard/contact/view/${params.id}`)
                };

                return (
                    <Box onClick={handleFirstNameClick}>
                        {params.value}
                    </Box>
                );
            }
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            cellClassName: "name-column--cell--capitalize"
        },
        {
            field: "rank",
            headerName: "Label",
            flex: 1,
            headerAlign: 'center',
            cellClassName: "name-column--cell--capitalize",
            renderCell: (params) => {
                const value = params.row.id;
                return (
                    <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%', // Ensure it fills the height of the container
                            width: '100%',  // Ensure it fills the width of the container
                        }}
                    >
                        {params.value ? params.value : '--'}
                    </Box>
                );
            }
        },
        {
            field: "gender",
            headerName: "Gender",
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "emailAddress",
            headerName: "Email Address",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                const handleFirstNameClick = async (data) => {
                    setId(data)
                    handleOpenEdit();
                };

                const viewAccountSection = async () => {
                    navigate(`/dashboard/recieved-payment/view/${params.id}`)
                }
                return (
                    <Box>

                        <Stack direction={"row"} spacing={2}>
                            <EditIcon variant='text' size='small' color='primary'

                                sx={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleFirstNameClick(params.row._id)}
                            />
                            <AutoStoriesRoundedIcon variant='text' size='small'
                                sx={{
                                    color: '#3366ff',
                                    cursor: 'pointer'
                                }}
                                onClick={() => viewAccountSection()}
                            />
                        </Stack>
                    </Box>
                );
            }
        },

    ];

   

    const fetchdata = async (searchText = '') => {
        setLoading(true); // Start loading
        try {
            const result = await apiget(`contact/list?search=${searchText}`);
            if (result && result.status === 200) {
                const sortedData = result?.data?.result.sort((a, b) => {
                    const nameA = a.firstName || '';
                    const nameB = b.firstName || '';
                    return nameA.localeCompare(nameB);
                });
                setContactList(sortedData); // Update contact list based on search
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false); // Stop loading after API call completes
    };


    const fetchReferralData = async () => {
        try {
            const result = await apiget('referral-source/list');
            if (result && result.status === 200) {

                const sourceType = result.data.result.filter(item => item.sourceType.toLowerCase().includes('contact'));
                setReferralSource(sourceType);
            }
        } catch (error) {
            console.error('Error fetching referral data:', error);
        }
    }


    useState(() => {
        fetchReferralData();
    }, [])

    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            {/* Add Contact Model */}
            <EditContact open={openEdit} handleClose={handleCloseEdit} id={id} fetchContact={fetchdata} />
            <AddContact open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />

            <Container>
                <TableStyle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4">
                            Contact
                        </Typography>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                            New Contact
                        </Button>
                    </Stack>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={contactList}
                                columns={columns}
                                components={{ Toolbar: () => <CustomToolbar selectedRowIds={selectedRowIds} searchText={searchText} setSearchText={setSearchText} fetchdata={fetchdata} /> }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                                loading={loading}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default Contact