import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// components
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import Iconify from '../../../components/iconify';
import AddArticle from './Add'
import { apidelete, apiget, deleteManyApi } from '../../../service/api';
import TableStyle from '../../../components/TableStyle';
import DeleteModel from '../../../components/Deletemodle'
import { constant } from '../../../constant';
import EditArticle from './Edit'
import View from '../../HelpCentreManagement/view'


function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
        await deleteManyApi('service-plans/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const ServicePlans = () => {
    const [userAction, setUserAction] = useState(null);
    const [ArticleList, setArticleList] = useState([]);
    const [ArticleData, setArticleData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState("");
    const [loading,setLoading]=useState(false);
    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")

    const handleOpenAdd = () => {
        setOpenAdd(true)
    }
    const handleCloseAdd = () => {
        setOpenAdd(false)
    }
    const handleOpenView = () => {
        setOpenView(true);
    }
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };


    // file delete api
    const deleteFile = async (id) => {
        const result = await apidelete(`service-plans/delete/${id}`)
        setUserAction(result)
    }
    const editArticleData = async (id) => {
        try {
            const result = await apiget(`service-plans/view/${id}`);
            if (result && result.status === 200) {
                setArticleData(result?.data);
            }
        } catch (error) {
            console.error("Error fetching Article data:", error);
        }
    };

    const columns = [
        {
            field: "title",
            headerName: "Plan title",
            flex: 1,

        },

        {
            field: "content",
            headerName: "Plan Detail",
            flex: 1,
        },
        {
            field: "page",
            headerName: "Page",
            flex: 1,
        },


        {
            field: "action",
            headerName: "Action",
            flex: 1,
            maxWidth: 150,
            minWidth: 150,
            renderCell: (params) => {

                const handleOpenEditDialog = async (data) => {
                    setOpenEdit(true);
                    editArticleData(data);
                    setEditId(data)
                }

                return (

                    <Stack direction={"row"} spacing={3} >
                        <EditIcon
                            variant='text'
                            size='small'
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        />
                        <DeleteIcon variant='outlined' sx={{ cursor: 'pointer' }} size='small' color='error'
                            onClick={() => deleteFile(params.row._id)} />
                        <Visibility variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary' onClick={() => {
                            editArticleData(params.row._id);
                            handleOpenView();
                        }} />
                    </Stack>

                );
            }
        },

    ];

    // fetch Articles list
    const fetchdata = async () => {
setLoading(true)
        const result = await apiget(userRole === "admin" ? `service-plans/list` : `service-plans/list/?createdBy=${userid}`)
        if (result && result.status === 200) {
            setArticleList(result?.data?.result)
        }
        setLoading(false)
    }



    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            {/* Add Article Model */}
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={ArticleData} header="Service Plan" />
            <AddArticle open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <EditArticle open={openEdit} singleArticleData={ArticleData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Service Plans
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                        Plan
                    </Button>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={ArticleList}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                                loading={loading}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default ServicePlans