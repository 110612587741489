import { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Iconify from '../../components/iconify';
import Add from './Add';
import { apidelete, apiget, apipost, deleteManyApi } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle';
import Edit from './Edit';

function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = () => {
        setOpendelete(true);
    };

    const deleteManyContact = async (data) => {
      const result=  await deleteManyApi('relationship/deletemany', data);
      if(result && result.status===200){
        fetchdata();
        handleCloseDelete();
      }
        
    };

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && (
                <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>
                    Delete
                </Button>
            )}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const RelationShipManagement = () => {
    const [userAction, setUserAction] = useState(null);
    const [RelationShipList, setRelationShipList] = useState([]);
    const [RelationShipData, setRelationShipData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState('');
    const [loading,setLoading]=useState(false);
    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem('userRole');
    const [opendelete, setOpendelete] = useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = () => {
        setOpendelete(true);
    };

    const deleteManyContact = async (data) => {
      const result=  await deleteManyApi('relationship/deletemany', data);
      if(result && result.status===200){
        fetchdata();
        handleCloseDelete();
      }
        
    };

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };
    const handleCloseAdd = () => {
        setOpenAdd(false);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    const deleteFile = async (id) => {
        const result = await apidelete(`relationship/delete/${id}`);
        if (result && result.status === 200) {
            setUserAction(result);
        }

    };

    const editRelationShipData = async (id) => {
        try {
            const result = await apiget(`relationship/view/${id}`);
            if (result && result.status === 200) {
                setRelationShipData(result?.data);
            }
        } catch (error) {
            console.error('Error fetching RelationShip data:', error);
        }
    };

    const columns = [
        {
            field: 'drag',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                <Draggable draggableId={params.row._id} index={params.row.index ?? params.row.id}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Iconify icon="mdi:drag" />
                        </div>
                    )}
                </Draggable>
            ),
        },

        {
            field: 'relation',
            headerName: 'Relation',
            flex: 1,
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            maxWidth: 150,
            minWidth: 150,
            renderCell: (params) => {
                const handleOpenEditDialog = async (data) => {
                    setOpenEdit(true);
                    await editRelationShipData(data);
                    setEditId(data);
                };

                return (
                    <Stack direction={'row'} spacing={3}>
                        <EditIcon
                            variant='outlined'
                            size='small'
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        />
                        <DeleteIcon sx={{ cursor: 'pointer' }} variant='outlined' size='small' color='error' onClick={handleOpenDelete} />
                        <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={[params.row._id]} />
                    </Stack>
                );
            },
        },
    ];

    const updatePolicyTypeOrder = async (data) => {
        const result = await apipost('relationShip/update-order', { relations: data });
        if (result && result.status === 200) {
            setUserAction(result);
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedRows = Array.from(RelationShipList);
        const [removed] = reorderedRows.splice(result.source.index, 1);
        reorderedRows.splice(result.destination.index, 0, removed);

        // Update the local state with the new order
        setRelationShipList(reorderedRows);

        // Prepare data to send to the backend
        const orderData = reorderedRows.map((row, index) => ({
            _id: row._id,
            order: index + 1, // Assuming order is 1-based
        }));

        // Update the order in the backend
        await updatePolicyTypeOrder(orderData);
    };

    const fetchdata = async () => {
        setLoading(true)
        const result = await apiget(userRole === 'admin' ? 'relationship/list' : `relationship/list/?createdBy=${userid}`);
        if (result && result.status === 200) {
            const sortedRelationShip = result?.data?.result.sort((a, b) => a.order - b.order);
            setRelationShipList(result?.data?.result);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchdata();
    }, [userAction]);

    return (
        <>
            <Add open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <Edit open={openEdit} singleRelationShipData={RelationShipData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">RelationShip</Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                        Relation
                    </Button>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: '550px', paddingTop: '15px' }}>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} style={{ height: '100%' }}>
                                            <DataGrid
                                                rows={RelationShipList.map((row, index) => ({
                                                    ...row,
                                                    index, // Add an index to maintain the order
                                                }))}
                                                loading={loading}
                                                columns={columns}
                                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                                checkboxSelection
                                                onRowSelectionModelChange={handleSelectionChange}
                                                rowSelectionModel={selectedRowIds}
                                                getRowId={(row) => row._id}
                                                autoHeight={false} // Disable autoHeight so the grid doesn't expand beyond the container
                                                sx={{
                                                    '& .MuiDataGrid-columnHeaders': {
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1,
                                                        backgroundColor: (theme) => theme.palette.action.selected, // Use the action color from the theme
                                                    },
                                                    '& .MuiDataGrid-toolbarContainer': {
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 2,

                                                    },
                                                    '& .MuiDataGrid-virtualScroller': {
                                                        overflowY: 'auto',
                                                        height: '450px', // Set height for the scrollable rows
                                                    },
                                                }}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card>
                    </Box>
                </TableStyle>
            </Container>


        </>
    );
};

export default RelationShipManagement;
