/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TextField, MenuItem, Select, FormLabel, FormControl, FormHelperText } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { apipost } from "../../service/api";
import Palette from '../../theme/palette'


const Add = (props) => {
    const { open, setOpen, fetchdata, setUserAction } = props
    const userid = localStorage.getItem('user_id')

    // -----------  validationSchema
    const validationSchema = yup.object({
        paymentMethod: yup.string().required("payment Method is required"),
        type: yup.string().required("Type is required"),
    });
    

    // -----------   initialValues
    const initialValues = {
        paymentMethod: "",
        type: "",
        createdBy: userid
    };


    const handleCloseAdd = () => {
        setOpen(false)
    }

    // add contact api
    const fileUpload = async (values) => {

        const data = new FormData()
        data.append("paymentMethod", values.paymentMethod)
        data.append("type", values.type)
        data.append("createdBy", values.createdBy)

        const result = await apipost('payment-method/add', values)
        fetchdata();
        handleCloseAdd();
        if (result && result.status === 200) {
            formik.resetForm();
            handleCloseAdd();
            
        }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            fileUpload(values)
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add New </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleCloseAdd}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Payment Method</FormLabel>
                                <TextField
                                    id="paymentMethod"
                                    type="text"
                                    name="paymentMethod"
                                    size="small"
                                    fullWidth
                                    className="custom-textfield"
                                    value={formik.values.paymentMethod}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)
                                    }
                                    helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} mb={4}>
                                <FormControl fullWidth>
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="type"
                                        name="type"
                                        size="small"
                                        fullWidth
                                        value={formik.values.type}
                                        onChange={formik.handleChange}
                                        error={formik.touched.type && Boolean(formik.errors.type)} // Change this line
                                    >
                                        <MenuItem key={'For Payment Pay'} value={'For Payment Pay'}>
                                            {'For Payment Pay'}
                                        </MenuItem>
                                        <MenuItem key={'For Payment Recieve'} value={'For Payment Recieve'}>
                                            {'For Payment Recieve'}
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText style={{ color: Palette.error.main }}>
                                        {formik.touched.type && formik.errors.type}  {/* Change this line */}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>


                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm();
                            handleCloseAdd();
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Add