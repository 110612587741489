import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DialogActions, DialogContent, FormLabel, Switch } from "@mui/material";
import { apipost } from "../../service/api";

const Addnotes = (props) => {
    const { open, handleClose, _id, setUserAction } = props;


    // Validation schema
    const validationSchema = yup.object({
        file: yup.string(),
        status: yup.string(),
        subject: yup.string().required("Subject is required"),
        note: yup.string().required("Note is required"),

    });

    // Initial form values
    const initialValues = {
        subject: "",
        note: "",
        status: "On",
        lead_id: _id,
        contact_id: _id,
        policy_id: _id,
        file: "",
    };



    // Formik configuration
    const formik = useFormik({
        initialValues,
        validationSchema,

        onSubmit: async (values) => {

            // Remove file from rest of the values
            const formData = new FormData();
            formData.append("file", values.file);
            formData.append("subject", values.subject);
            formData.append("status", values.status);
            formData.append("note", values.note);
            formData.append("lead_id", values.lead_id);
            formData.append("contact_id", values.contact_id);
            formData.append("policy_id", values.policy_id);

            try {
                const result = await apipost("note/add", formData);
                setUserAction(result);

                if (result && result.status === 201) {
                    formik.resetForm();
                    handleClose();
                    toast.success(result.data.message);
                }

            } catch (error) {
                toast.error("An error occurred while adding the note.");
            }
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Create Note</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12}>
                                    <FormLabel  >Upload MP3 File
                                    </FormLabel>
                                    <TextField
                                        type="file"
                                        name="file"
                                        InputProps={{
                                            inputProps: {
                                                accept: "audio/mp3",
                                            },
                                        }}
                                        onChange={(event) => {
                                            formik.setFieldValue("file", event.currentTarget.files[0]);
                                        }}
                                        error={
                                            formik.touched.file &&
                                            Boolean(formik.errors.file)
                                        }
                                        helperText={
                                            formik.touched.file && formik.errors.file
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel  >Subject<Typography component="span" sx={{ color: 'red' }} >*</Typography>
                                    </FormLabel>
                                    <TextField
                                        id="subject"
                                        name="subject"
                                        size="small"
                                        maxRows={10}
                                        fullWidth
                                        value={formik.values.subject}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.subject &&
                                            Boolean(formik.errors.subject)
                                        }
                                        helperText={
                                            formik.touched.subject &&
                                            formik.errors.subject
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel  >Note<Typography component="span" sx={{ color: 'red' }} >*</Typography>
                                    </FormLabel>
                                    <TextField
                                        id="note"
                                        name="note"
                                        size="small"
                                        rows={5}
                                        multiline
                                        fullWidth
                                        value={formik.values.note}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.note &&
                                            Boolean(formik.errors.note)
                                        }
                                        helperText={
                                            formik.touched.note &&
                                            formik.errors.note
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel>Status</FormLabel>
                                    <Switch
                                        checked={formik.values.status === 'On'}
                                        onChange={(event) => {
                                            formik.setFieldValue('status', event.target.checked ? 'On' : 'Off');
                                        }}
                                        name="status"
                                        color="primary" // Change color to primary or secondary as needed
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ textTransform: "capitalize" }}
                        color="secondary"
                        onClick={formik.handleSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm();
                            handleClose();
                        }}
                        color="error"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Addnotes;
