import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ClearIcon from "@mui/icons-material/Clear";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import moment from 'moment'
import Palette from '../../theme/palette'
import { constant } from '../../constant'



const view = ({ open, setOpen, data, header, imageFolder }) => {

   
    const handleClose = () => {
        setOpen(false);
    };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i += 1) {
            if (i <= rating) {
                stars.push(<StarIcon key={i} color="warning" />);
            } else {
                stars.push(<StarBorderIcon key={i} color="warning" />);
            }
        }
        return stars;
    };

    return (
        <>
            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",

                    }}
                >
                    <Typography variant="h6">{header}</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={{ xs: 0, sm: 5, md: 2 }}


                    >
                        {data.file && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1" mb={2}>File:</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>
                                        {data?.file ? (
                                            // Check the file extension
                                            (/\.(jpg|jpeg|png|gif|txt)$/i).test(data.file) ? (
                                                <img src={`${constant.baseUrl}uploads/${imageFolder}/${data.file}`} alt='' style={{ maxWidth: '100%', height: 'auto' }} />
                                            ) : (/\.(pdf)$/i).test(data.file) ? (
                                                // Display the PDF
                                                <embed src={`${constant.baseUrl}uploads/${imageFolder}/${data.file}`} type="application/pdf" width="100%" height="500px" />
                                            ) : (
                                                // If the file is neither an image nor a PDF, show a fallback
                                                <span>Unsupported file format</span>
                                            )
                                        ) : "--"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        {(data.files && data.files.length > 0) && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1" mb={2}>Files:</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>
                                        {/* Loop through the files array */}
                                        {data.files.map((file, index) => (
                                            <div key={index} style={{ marginBottom: '10px' }}>
                                                {/* Check the file extension using fileName */}
                                                {(/\.(jpg|jpeg|png|gif|txt)$/i).test(file.fileName) ? (
                                                    <img src={`${constant.baseUrl}${file.path}`} alt='' style={{ maxWidth: '100%', height: 'auto' }} />
                                                ) : (/\.(pdf)$/i).test(file.fileName) ? (
                                                    <embed src={`${constant.baseUrl}${file.path}`} type="application/pdf" width="100%" height="500px" />
                                                ) : (
                                                    <span>Unsupported file format: {file.fileName}</span>
                                                )}
                                            </div>
                                        ))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}


                        {data.question && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Question :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.question ? data?.question : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.answer && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Answer :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.answer ?
                                    <div dangerouslySetInnerHTML={{ __html: data?.answer }} />
                                    : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.title && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Title :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.title ? data?.title : "--"}</Typography>
                            </Grid>
                        </Grid>}

                        {data.hastags && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">HashTags :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.hastags ? data?.hastags : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.name && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Name :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.name ? data?.name : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.fullName && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">FullName :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.fullName ? data?.fullName : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.position && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Position :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.position ? data?.position : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.page && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Page :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.page ? data?.page : "--"}</Typography>
                            </Grid>
                        </Grid>}

                        {data.content && <Grid item xs={12} sm={12}>
                            <Grid pb={2}>
                                <Typography variant="body1">Detail :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.content ?
                                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                                    : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.rating && <Grid item xs={12} sm={12}>
                            <Grid pb={2}>
                                <Typography variant="body1">Rating :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>
                                    {data?.rating ? renderStars(data.rating) : "--"}
                                </Typography>
                            </Grid>
                        </Grid>}
                        {data.paymentMethod && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Payment Method :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.paymentMethod ? data?.paymentMethod : "no description"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.date && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.date ? data?.date : "no description"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.amount && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">{data?.type === 'payment' ? 'Amount :' : 'Discount'}</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.amount ? data?.amount : "no description"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.desc && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Description :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.desc ? data?.desc : "no description"}</Typography>
                            </Grid>
                        </Grid>}
                        {data.claimNotes && <Grid item xs={12} sm={12} md={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Claim Notes :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.claimNotes ? data?.claimNotes : "--"}</Typography>
                            </Grid>
                        </Grid>}
                        <Grid item xs={12} sm={12}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Create Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>
                                    {moment(data?.createdOn).format('lll')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid pb={3}>
                                <Typography variant="body1">Modified Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>
                                    {moment(data?.modifiedOn).format('lll')}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>

            </Dialog>
        </>
    )
}

export default view