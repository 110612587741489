import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// components
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import Iconify from '../../components/iconify';
import AddOurTeam from './Add'
import { apidelete, apiget, deleteManyApi } from "../../service/api";
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle'
import EditOurTeam from './Edit'
import View from './view'


function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
        await deleteManyApi('help-center/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const HelpCentre = () => {
    const [userAction, setUserAction] = useState(null);
    const [helpCenterList, setHelpCenterList] = useState([]);
    const [helpCenterData, setHelpCenterData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState("");
    const [loading, setLoading] = useState(false);
    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")

    const handleOpenAdd = () => {
        setOpenAdd(true)
    }
    const handleCloseAdd = () => {
        setOpenAdd(false)
    }
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }
    const handleOpenView = () => {
        setOpenView(true);
    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };


    // file delete api
    const deleteFile = async (id) => {
        const result = await apidelete(`help-center/delete/${id}`)
        setUserAction(result)
    }
    const editHelpCenterData = async (id) => {
        try {
            const result = await apiget(`help-center/view/${id}`);
            if (result && result.status === 200) {

                setHelpCenterData(result?.data?.helpCenter);
            }
        } catch (error) {
            console.error("Error fetching OurTeam data:", error);
        }
    };

    const columns = [
        {
            field: "title",
            headerName: "Plan Title",
            flex: 1,
        },
        {
            field: "content",
            headerName: "Content",
            flex: 1,
        },
        {
            field: "file",
            headerName: "File",
            flex: 1,

        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            maxWidth: 150,
            minWidth: 150,
            renderCell: (params) => {

                const handleOpenEditDialog = async (data) => {
                    setOpenEdit(true);
                    editHelpCenterData(data);
                    setEditId(data)
                }

                return (

                    <Stack direction={"row"} spacing={3}>
                        <EditIcon
                            variant='text'
                            sx={{ cursor: 'pointer' }}
                            size='small'
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        />
                        <DeleteIcon variant='outlined' sx={{ cursor: 'pointer' }} size='small'
                            color='error' onClick={() => deleteFile(params.row._id)} />
                        <Visibility variant='outlined' sx={{ cursor: 'pointer' }} size='small' color='primary' onClick={() => {
                            editHelpCenterData(params.row._id);
                            handleOpenView();
                        }} />
                    </Stack>

                );
            }
        },

    ];

    // fetch OurTeams list
    const fetchdata = async () => {
        setLoading(true)
        const result = await apiget(`help-center/list`)
        if (result && result.status === 200) {
            setHelpCenterList(result?.data?.result)
        }
        setLoading(false)
    }



    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            {/* Add OurTeam Model */}
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={helpCenterData} header="Help Center" imageFolder="helpCenter" />
            <AddOurTeam open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <EditOurTeam open={openEdit} singleOurTeamData={helpCenterData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Help Center Management
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                        Add Insurance
                    </Button>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={helpCenterList}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                                loading={loading}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default HelpCentre