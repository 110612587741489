/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
// @mui
import { Stack, Button, Typography, Box, Grid, TextField, Switch } from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { Share, Download, Delete } from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AttachmentIcon from '@mui/icons-material/Attachment';
// sections
import AddDocument from './Add'
import Edit from './Edit';
import { apiput, apiget, deleteManyApi } from '../../../service/api';
import TableStyleTwo from '../../../components/TableStyleTwo';
import DeleteModel from '../../../components/Deletemodle'
import { constant } from '../../../constant';
import View from '../../HelpCentreManagement/view'

// ----------------------------------------------------------------------

const ExistingPolicyDocument = ({ rows, toggleVisibilityExistingPolicy, userAction, isVisibleExistingPolicy, setUserAction, _id }) => {

    const userRole = localStorage.getItem("userRole");
    const [openAdds, setOpenAdds] = useState(false);
    const handleOpenAdds = () => setOpenAdds(true);
    const handleCloseAdds = () => setOpenAdds(false);

    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openDelete, setOpenDelete] = useState(false)
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const [openView, setOpenView] = useState(false);
    const handleOpenView = () => setOpenView(true);

    const [searchText, setSearchText] = useState(''); // Search input state
    const [filteredRows, setFilteredRows] = useState(rows); // Filtered rows state
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [policyDocumentData, setPolicyDocumentData] = useState([]);



    const deleteMany = async (deleteId) => {
        const result = await deleteManyApi('exiting-policy/deletemany', deleteId)
        if (result && result.status === 200) {

            setUserAction(result)
            handleCloseDelete();
        }
    }


    const downloadFile = async (id) => {
        const result = await apiget(`exiting-policy/file/${id}`)
        setUserAction(result);

    }

    const EditFileName = async (id) => {
        fetchPolicyDocument(id)
        handleOpenEdit();
        //   const result= await apiput(`policydocument/edit/${id}`);


    }

    const fetchPolicyDocument = async (id) => {
        const result = await apiget(`exiting-policy/view/${id}`);
        if (result && result.status === 200) {
            setPolicyDocumentData(result?.data?.result)
            // setUserAction(result?.data?.result)
        }
    }

    //  chnage status 

    const changePolicyStatus = async (id, newStatus) => {

        const data = { status: newStatus };
        try {
            const result = await apiput(`exiting-policy/convert/${id}`, data);

            if (result && result.status === 200) {
                setUserAction(result);
            }

        } catch (error) {
            console.error("Error updating note status:", error);
        }

    }


    useEffect(() => {
        if (searchText.trim() === '') {
            // Create a copy of rows before sorting to avoid modifying the original array
            const sortedData = [...(rows ?? [])].sort((a, b) => {
                const nameA = a?.fileName?.toLowerCase() || '';
                const nameB = b?.fileName?.toLowerCase() || '';

                return nameA.localeCompare(nameB);
            });

            setFilteredRows(sortedData); // Set the sorted data to filteredRows
        } else {
            const filteredData = rows && rows.filter((row) => {
                setLoading(true);

                return (
                    row.fileName?.toLowerCase().startsWith(searchText.toLowerCase())
                );
            });

            setFilteredRows(filteredData);
            setLoading(false);
        }
    }, [searchText, rows]);


    const columns = [

        {
            field: "file",
            headerName: "File",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {

                const ViewPolicyDocument = () => {
                    fetchPolicyDocument(params.row._id)
                    handleOpenView();
                }

                return (
                    <Box onClick={ViewPolicyDocument}>
                        {params.value}
                    </Box>
                )
            }
        },
        {
            field: "fileName",
            headerName: "File Name",
            flex: 1,

        },
        {
            field: "policyType",
            headerName: "Policy Type",
            flex: 1,

        },
        {
            field: "policyCompany",
            headerName: "Policy Company",
            flex: 1,

        },

        {
            field: "policyStartDate",
            headerName: "Policy Start Date",
            flex: 1,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;
                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }
                return '--';
            },

        },
        {
            field: "policyEndDate",
            headerName: "Policy End Date",
            flex: 1,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;
                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }
                return '--';
            },

        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: (params) => {

                const handleStatusChange = (event) => {

                    const newStatus = event.target.checked ? 'yes' : 'no';
                    
                    changePolicyStatus(params.row._id, newStatus);
                    return newStatus;
                };

                return (
                    <Switch
                        checked={params.row.status === 'yes'}
                        onChange={handleStatusChange}
                        name="status"
                        color="primary"
                    />
                )
            }
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            headerAlign: 'center', // This aligns the header text to the center
            renderCell: (params) => {


                const handleFirstNameClick = async () => {
                    await downloadFile(params.row._id);
                };
                const downloadUrl = `${constant.baseUrl}exiting-policy/file/${params.row._id}`;

                const handleShareClick = () => {
                    const message = `Click this link to Download Existing Policy Document : ${downloadUrl}`;
                    const encodedMessage = encodeURIComponent(message);
                    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
                    window.open(whatsappUrl, '_blank');
                };

                const handleDeleteFamiyMember = (Id) => {
                    setSelectedId(Id);
                    handleOpenDelete();
                }

                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="100%"
                    >
                        <Stack direction="row" spacing={1}>
                            <a href={downloadUrl} download style={{ color: 'inherit', textDecoration: 'none' }}>
                                <Download variant="contained" color="primary" size="small" onClick={handleFirstNameClick} />
                            </a>

                            {userRole === 'admin' && (
                                <Delete variant="outlined" sx={{ cursor: 'pointer' }} size="small" color="error" onClick={() => handleDeleteFamiyMember(params.row._id)} />
                            )}


                            {userRole === 'admin' && (
                                <EditIcon variant="outlined" sx={{ cursor: 'pointer' }} size="small" color="primary" onClick={() => EditFileName(params.row._id)} />
                            )}

                            <Share
                                variant="outlined"
                                size="small"
                                sx={{ cursor: 'pointer' }}
                                color="primary"
                                onClick={handleShareClick}
                            />
                        </Stack>
                    </Box>


                );
            }
        }


    ];

    return (
        <>
            {/* Add Document Model */}
            <DeleteModel opendelete={openDelete} handleClosedelete={handleCloseDelete} deletedata={deleteMany} id={[selectedId]} />
            <AddDocument open={openAdds} handleClose={handleCloseAdds} setUserAction={setUserAction} _id={_id} />
            <Edit open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} _id={_id} data={policyDocumentData} />
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={policyDocumentData} header="Existing Policy Document" imageFolder="existingPolicy" />
            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityExistingPolicy}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleExistingPolicy ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">Existing Policy Documents</Typography>
                            {isVisibleExistingPolicy && <TextField
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                style={{ marginBottom: '10px' }}
                            />}
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleExistingPolicy && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdds}
                                >
                                    Add Document
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisibleExistingPolicy &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                            loading={loading}
                        />
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default ExistingPolicyDocument