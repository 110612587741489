/* eslint-disable react/prop-types */
import * as React from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControl, FormHelperText, FormLabel, Select ,InputAdornment} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { apipost } from "../../service/api";


const Addclaim = (props) => {
    const { open, handleClose, _id, policyType, setUserAction } = props;

    // -----------  validationSchema
    const validationSchema = yup.object({
        file: yup
            .array()
            .of(
                yup.mixed().test(
                    "fileSize",
                    "File size is too large",

                    (value) => value && value.size <= 20000000 // max file size 20MB
                ).test(
                    "fileType",
                    "Unsupported File Format",
                    (value) => value && ['image/jpeg','image/svg', 'image/png', 'application/pdf'].includes(value.type)
                )
            )
            .required("Files are required"),
        fileName: yup.string(),
        policyNumber: yup.string(),
        claimType: yup.string().required("Claim Type is required"),
        claimAmount: yup.string(),
        claimDate: yup.string(),
        claimStatus: yup.string(),
        claimNotes: yup.string(),
    });


    // -----------   initialValues
    const initialValues = {
        policyNumber: "",
        file: [],  // Initialize as an empty array
        fileName: "",
        claimType: "",
        claimAmount: "",
        claimDate: "",
        claimStatus: "",
        claimNotes: "",
        contact_id: _id,
        policy_id: _id
    };

    // add claim api
    const addClaim = async (values) => {

        const data = new FormData();


        // Other form fields
         data.append("fileName", values.fileName);
        data.append("claimType", values.claimType);
        data.append("claimAmount", values.claimAmount);
        data.append("claimDate", values.claimDate);
        data.append("claimStatus", values.claimStatus);
        data.append("claimNotes", values.claimNotes);
        data.append("contact_id", values.contact_id);
        data.append("policy_id", values.policy_id);

        if (formik.values.file && formik.values.file.length > 0) {
            formik.values.file.forEach((file) => {
                data.append('file', file);  // Append each file
            });
        }

        const result = await apipost('claim/add', data);
        setUserAction(result);

        if (result && result.status === 201) {
            formik.resetForm();
            handleClose();
            toast.success(result.data.message);
        }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            addClaim(values);
            resetForm();
        },
    });

    
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add Claim</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form   onSubmit={formik.handleSubmit}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policy Number</FormLabel>
                                    <TextField
                                        id="policyNumber"
                                        name="policyNumber"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.policyNumber}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.policyNumber &&
                                            Boolean(formik.errors.policyNumber)
                                        }
                                        helperText={
                                            formik.touched.policyNumber && formik.errors.policyNumber
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Policy Type<Typography component={'span'} color={'error'} >*</Typography></FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="claimType"
                                            name="claimType"
                                            size="small"
                                            value={formik.values.claimType}
                                            onChange={formik.handleChange}
                                            error={formik.touched.claimType && Boolean(formik.errors.claimType)}
                                        >
                                            {
                                                policyType && policyType.map((item, index) => (
                                                    <MenuItem key={index} value={item.policyTypes}>{item.policyTypes}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.claimType && Boolean(formik.errors.claimType)
                                            }
                                        >
                                            {formik.touched.claimType && formik.errors.claimType}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Claim Date</FormLabel>
                                    <TextField
                                        id="claimDate"
                                        name="claimDate"
                                        size="small"
                                        fullWidth
                                        value={formik.values.claimDate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.claimDate &&
                                            Boolean(formik.errors.claimDate)
                                        }
                                        helperText={
                                            formik.touched.claimDate && formik.errors.claimDate
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormLabel>Claim Amount</FormLabel>
                                    <TextField
                                        id="claimAmount"
                                        name="claimAmount"
                                        size="small"
                                        type="number"
                                        fullWidth
                                        value={formik.values.claimAmount}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.claimAmount &&
                                            Boolean(formik.errors.claimAmount)
                                        }
                                        helperText={
                                            formik.touched.claimAmount && formik.errors.claimAmount
                                        }
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth>
                                        <FormLabel>Claim Status</FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="claimStatus"
                                            name="claimStatus"
                                            size="small"
                                            value={formik.values.claimStatus}
                                            onChange={formik.handleChange}
                                            error={formik.touched.claimStatus && Boolean(formik.errors.claimStatus)}
                                        >
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem value="Paid">Paid</MenuItem>
                                            <MenuItem value="Under Investigation">Under Investigation</MenuItem>
                                            <MenuItem value="Approved">Approved</MenuItem>
                                            <MenuItem value="Denied">Denied</MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.claimStatus && Boolean(formik.errors.claimStatus)
                                            }
                                        >
                                            {formik.touched.claimStatus && formik.errors.claimStatus}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Upload Files</FormLabel>

                                    {/* Hidden file input field */}
                                    <input
                                        id="file"
                                        name="file"
                                        type="file"
                                        multiple
                                        style={{ display: 'none' }} // Hide the input field
                                        onChange={(event) => {
                                            const filesArray = Array.from(event.currentTarget.files); // Convert files to array
                                            formik.setFieldValue('file', filesArray); // Set formik value
                                        }}
                                    />

                                    {/* Button to trigger file input */}
                                    <Button
                                        variant="outlined"
                                        onClick={() => document.getElementById('file').click()} // Trigger file selection dialog
                                    >
                                        Choose Files
                                    </Button>

                                    {/* Display error message if validation fails */}
                                    <FormHelperText
                                        error={formik.touched.file && Boolean(formik.errors.file)}
                                    >
                                        {formik.touched.file && formik.errors.file}
                                    </FormHelperText>
                                </Grid>

                                {/* <Grid item xs={12} sm={12}>
                                    <FormLabel>FileName</FormLabel>
                                    <TextField
                                        id="fileName"
                                        name="fileName"
                                        size="small"
                                        fullWidth
                                        value={formik.values.fileName}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.fileName &&
                                            Boolean(formik.errors.fileName)
                                        }
                                        helperText={
                                            formik.touched.fileName && formik.errors.fileName
                                        }
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={12}>
                                    <FormLabel>Claim Notes</FormLabel>
                                    <TextField
                                        id="claimNotes"
                                        name="claimNotes"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={formik.values.claimNotes}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.claimNotes &&
                                            Boolean(formik.errors.claimNotes)
                                        }
                                        helperText={
                                            formik.touched.claimNotes && formik.errors.claimNotes
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleClose();
                        formik.resetForm();
                    }
                    }
                        color="error">
                        Cancel
                    </Button>
                    <Button type="submit" onClick={formik.handleSubmit} variant="contained" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Addclaim;
