/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormLabel } from "@mui/material";
import { apipost, apiput } from "../../../service/api";

const Edit = (props) => {
    const { open, handleClose, setUserAction, _id, data } = props
    const userid = localStorage.getItem('user_id')
    const documentId = data._id;



    // -----------  validationSchema
    const validationSchema = yup.object({

        file: yup.string(),
        fileName: yup.string().required("File Name is required"),
        policyCompany: yup.string(),
        policyType: yup.string(),
        status:yup.string(),
        policyStartDate: yup.date().required('Policy Start Date is required'),
        policyEndDate: yup.date().required('Policy End Date is required'),
    });

    // -----------   initialValues
    const initialValues = {
        file: data?.file,
        fileName: data?.fileName,
        contact_id: _id,
        status:data?.status || "no",
        policyStartDate: data?.policyStartDate,
        policyEndDate: data?.policyEndDate,
        policyCompany: data?.policyCompany,
        policyType: data?.policyType,
        createdBy: userid
    };

    // add contact api
    const fileUpload = async (values) => {
        const data = new FormData()
        data.append("file", values.file)
        data.append("fileName", values.fileName)
        data.append("status", values.status)
        data.append("policyStartDate", values.policyStartDate)
        data.append("policyEndDate", values.policyEndDate)
        data.append("policyCompany", values.policyCompany)
        data.append("policyType", values.policyType)
        data.append("contact_id", values.contact_id)
        data.append("createdBy", values.createdBy)

        const result = await apiput(`exiting-policy/edit/${documentId}`, data)

        setUserAction(result)

        if (result && result.status === 200) {
            formik.resetForm();
            handleClose();
            // toast.success(result.data.message)
        }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            fileUpload(values)
        },
    });


    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Edit</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data" onSubmit={formik.handleSubmit} >
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Upload File</FormLabel>
                                <TextField
                                    id="file"
                                    name="file"
                                    size="small"
                                    maxRows={10}
                                    fullWidth
                                    type="file"
                                    multiple
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => {

                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                    error={
                                        formik.touched.file &&
                                        Boolean(formik.errors.file)
                                    }
                                    helperText={
                                        formik.touched.file && formik.errors.file
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Uploaded File</FormLabel>
                                <TextField
                                   
                                    size="small"
                                    fullWidth
                                    
                                    value={formik.values.file || ''}  // Ensure value is never undefined, fallback to empty string
                                    inputProps={{ readOnly: true }}   // Set readOnly correctly
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>FileName</FormLabel>
                                <TextField
                                    id="fileName"
                                    name="fileName"
                                    size="small"
                                    fullWidth
                                    value={formik.values.fileName}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.fileName && Boolean(formik.errors.fileName)
                                    }
                                    helperText={formik.touched.fileName && formik.errors.fileName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Policy Type</FormLabel>
                                <TextField
                                    id="policyType"
                                    name="policyType"
                                    size="small"
                                    fullWidth
                                    value={formik.values.policyType}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.policyType && Boolean(formik.errors.policyType)
                                    }
                                    helperText={formik.touched.policyType && formik.errors.policyType}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Policy Company</FormLabel>
                                <TextField
                                    id="policyCompany"
                                    name="policyCompany"
                                    size="small"
                                    fullWidth
                                    value={formik.values.policyCompany}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.policyCompany && Boolean(formik.errors.policyCompany)
                                    }
                                    helperText={formik.touched.policyCompany && formik.errors.policyCompany}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormLabel>Policy Start Date</FormLabel>
                                <TextField
                                    id="policyStartDate"
                                    type="date"
                                    fullWidth
                                    value={formik.values.policyStartDate}
                                    onChange={formik.handleChange}
                                    error={formik.touched.policyStartDate && Boolean(formik.errors.policyStartDate)}
                                    helperText={formik.touched.policyStartDate && formik.errors.policyStartDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormLabel>Policy End Date</FormLabel>
                                <TextField
                                    id="policyEndDate"
                                    type="date"
                                    fullWidth
                                    value={formik.values.policyEndDate}
                                    onChange={formik.handleChange}
                                    error={formik.touched.policyEndDate && Boolean(formik.errors.policyEndDate)}
                                    helperText={formik.touched.policyEndDate && formik.errors.policyEndDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Edit