/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import { TextField, MenuItem, Select, FormLabel, FormControl, FormHelperText, InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import Palette from '../../theme/palette'
import { apipost, apiput } from "../../service/api";
import formatNumber from '../../components/AmountFormatter'


const Add = (props) => {
    const { open, handleClose, method, setUserAction, dueId, dueAmount, _id, type, fetchRecievedPayment } = props
    const userid = localStorage.getItem('user_id')

    const validationSchema = yup.object({
        paymentMethod: yup.string().when('type', {
            is: (val) => val?.toLowerCase().includes('payment'),
            then: (schema) => schema.required("Payment Method is required"),

        }),
        date: yup.string().when('type', {
            is: (val) => val?.toLowerCase().includes('payment'),
            then: (schema) => schema.required("Date is required"),

        }),
        amount: yup.string().required('required'),
        desc: yup.string(),
        type: yup.string(),
        createdBy: yup.string(),
    });


    const initialValues = {
        paymentMethod: "",
        date: "",
        amount: dueAmount || "",
        desc: "",
        type: type || "",
        contact_id: _id,
        createdBy: userid
    };


    const fileUpload = async (values) => {

        const result = await apipost('recieved-payment/upload', values)

        if (result && result.status === 200) {

            formik.resetForm();
            handleClose();
            if (dueId) {
                const response = await apiput(`payment-plan/update-status/${dueId}`, { status: 'Paid' })
                if (response && response.status === 200) {
                    setUserAction(result);
                    fetchRecievedPayment();
                }

            }
            setUserAction(result);
            fetchRecievedPayment();

        }
    }


    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            const amount = values.amount.replace(/,/g, '');
            const updatedValues = {
                ...values,
                amount: Number(amount) || ''
            }
            fileUpload(updatedValues)
        },
    });




    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">{type === 'payment' ? 'Add Payment' : 'Add Discount'}</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >


                            {type === 'payment' && <> <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Payment Method</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        size='small'
                                        fullWidth
                                        value={formik.values.paymentMethod}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.paymentMethod &&
                                            Boolean(formik.errors.paymentMethod)
                                        }
                                        helpertext={
                                            formik.touched.paymentMethod && formik.errors.paymentMethod
                                        }
                                    >
                                        {method && method.length > 0 && method.map((option, index) => (

                                            <MenuItem key={index} value={option.paymentMethod}>
                                                {option.paymentMethod}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.paymentMethod && formik.errors.paymentMethod}</FormHelperText>
                                </FormControl>
                            </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Date of Payment</FormLabel>
                                    <TextField
                                        id="date"
                                        name="date"
                                        type="date"
                                        size="small"
                                        fullWidth
                                        value={formik.values.date}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.date && Boolean(formik.errors.date)
                                        }
                                        helperText={formik.touched.date && formik.errors.date}
                                        InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                                    />
                                </Grid> </>}
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>{type === 'payment' ? "Amount" : 'Discount'}</FormLabel>
                                <TextField
                                    id="amount"
                                    name="amount"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    value={formik.values.amount}
                                    onChange={(e) => {
                                        const formattedValue = formatNumber(e.target.value); // Format the input value
                                        formik.setFieldValue('amount', formattedValue); // Update Formik state
                                    }}
                                    onBlur={() => {
                                        const value = formik.values.amount;

                                        // Format the value after user is done typing (on blur)
                                        if (value) {
                                            const formattedValue = formatNumber(value);
                                            formik.setFieldValue('amount', formattedValue); // Apply formatting here
                                        }
                                    }}
                                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                                    helperText={formik.touched.amount && formik.errors.amount}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Description</FormLabel>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    type="text"
                                    rows={5}
                                    multiline
                                    size="small"
                                    fullWidth
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.desc && Boolean(formik.errors.desc)
                                    }
                                    helperText={formik.touched.desc && formik.errors.desc}
                                />
                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Add