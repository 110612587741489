// @mui
import PropTypes from 'prop-types';
import { Card, Typography, CardHeader, CardContent, Stack, Box } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
// utils
import { fDateTime } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

AppOrderTimeline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppOrderTimeline({ title, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader
        title={
          <Stack direction="row" justifyContent="space-between">
            {title}
            <span style={{ fontSize: '0.8rem', color: '#000', display: 'flex', gap: '2vw' }}>
              <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
                <Box
                  component={'span'}
                  sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'success.main', // Success color for "Held"
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />
                {`Held`}
              </span>

              <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
                <Box
                  component={'span'}
                  sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'warning.main', // Warning color for "Planned"
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />
                {`Planned`}
              </span>

              <span style={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
                <Box
                  component={'span'}
                  sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'error.main', // Error color for "Not Held"
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />
                {`Not Held`}
              </span>
            </span>
          </Stack>

        }
      />


      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <Timeline>
          {list.map((item, index) => (
            <OrderItem key={item._id} item={item} isLast={index === list.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired, // _id is a required string
    subject: PropTypes.string.isRequired, // subject is a required string
    status: PropTypes.string.isRequired, // status is a required string
    startDateTime: PropTypes.string.isRequired, // startDateTime is a required string (could also use PropTypes.instanceOf(Date) if appropriate)
    duration: PropTypes.string.isRequired, // duration is a required string
  }).isRequired, // item is required
};

function OrderItem({ item, isLast }) {
  const { status, subject, startDateTime, duration } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={

            (status === 'Held' && 'success') ||
            (status === 'Planned' && 'warning') ||
            (status === 'Not Held' && 'error')
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{subject}</Typography>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {fDateTime(startDateTime)}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {`duration : ${duration}`}
          </Typography>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
}
