import React, { useEffect, useState } from 'react';
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    Box,
} from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import TableStyle from '../../../../components/TableStyle';
import { apiget, deleteManyApi, apidelete, apipost } from '../../../../service/api';
import DeleteModel from '../../../../components/Deletemodle';
import AddPolicyType from './Add';
import EditPolicyType from './Edit';
import Iconify from '../../../../components/iconify'

function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = async () => {
        setOpendelete(true);
    };

    const deleteManyPolicyType = async (data) => {
        await deleteManyApi('policy-types/deletemany', data);
        fetchdata();
        handleCloseDelete();
    };

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && (
                <Button
                    variant="text"
                    sx={{ textTransform: 'capitalize' }}
                    startIcon={<DeleteOutline />}
                    onClick={handleOpenDelete}
                >
                    Delete
                </Button>
            )}
            <DeleteModel
                opendelete={opendelete}
                handleClosedelete={handleCloseDelete}
                deletedata={deleteManyPolicyType}
                id={selectedRowIds}
            />
        </GridToolbarContainer>
    );
}

const PolicyType = () => {
    const [policyType, setPolicyType] = useState([]);
    const [open, setOpen] = useState(false);
    const [userAction, setUserAction] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState('');
    const [deleteId, setDeleteID] = useState(null);
    const [opendelete, setOpendelete] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [loading,setLoading]=useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = async () => {
        setOpendelete(true);
    };
    const handleOpenAdd = () => {
        setOpenAdd(true);
    }

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem('userRole');

    const deletePolicyType = async (deleteId) => {
        const result = await apidelete(`policy-types/delete/${deleteId}`);
        setUserAction(result);
        fetchdata();
        handleCloseDelete();
    };

    const columns = [
        {
            field: 'drag',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                <Draggable draggableId={`${params.row._id}`} index={params.row.index}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Iconify icon="mdi:drag" />
                        </div>
                    )}
                </Draggable>
            ),
        },
        {
            field: 'policyTypes',
            headerName: 'Policy Type',
            flex: 1,
            cellClassName: 'name-column--cell name-column--cell--capitalize',
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            maxWidth: 200,
            minWidth: 100,
            renderCell: (params) => {
                const handleOpenEditDialog = async (data) => {
                    setOpen(true);
                    setEditId(data);
                };
                return (
                    <Stack direction={'row'}>
                        <Button
                            variant="text"
                            size="small"
                            color="primary"
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            variant="text"
                            size="small"
                            color="error"
                            onClick={() => {
                                handleOpenDelete();
                                setDeleteID(params.row._id);
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const fetchdata = async () => {
        setLoading(true)
        const result = await apiget('policy-types/list');
        if (result && result.status === 200) {
            const sortedPolicyTypes = result.data.sort((a, b) => a.order - b.order);
            setPolicyType(sortedPolicyTypes);
            
        }
        setLoading(false)
    };

    const updatePolicyTypeOrder = async (data) => {
        const result = await apipost('policy-types/update-order', { policyTypes: data });
        if (result && result.status === 200) {
            setUserAction(result);
        }
    };

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedRows = Array.from(policyType);
        const [removed] = reorderedRows.splice(result.source.index, 1);
        reorderedRows.splice(result.destination.index, 0, removed);

        setPolicyType(reorderedRows);

        // Prepare data to send to the backend
        const orderData = reorderedRows.map((row, index) => ({
            _id: row._id,
            order: index + 1, // Assuming order is 1-based
        }));

        // Update the order in the backend
        await updatePolicyTypeOrder(orderData);
    };

    useEffect(() => {
        fetchdata();
    }, [userAction]);

    return (
        <>
            <AddPolicyType open={openAdd} setOpen={setOpenAdd} fetchdata={fetchdata} />
            <DeleteModel
                opendelete={opendelete}
                handleClosedelete={handleCloseDelete}
                deletedata={deletePolicyType}
                id={deleteId}
            />
            <EditPolicyType
                fetchdata={fetchdata}
                setOpen={setOpen}
                open={open}
                editId={editId}
            />
            <Container>
                <TableStyle>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={5}
                    >
                        <Typography variant="h4">Policy Type</Typography>
                        <Button variant="contained" onClick={handleOpenAdd} startIcon={<Iconify icon="eva:plus-fill" />} >
                            Add Policy Type
                        </Button>


                    </Stack>
                    <Box width="100%" style={{ overflow: 'hidden' }}>
                        <Card style={{ height: '550px', width: '100%', overflow: "hidden", paddingTop: '15px', scrollX: 'none' }}>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ height: '100%', overflow: 'hidden', scrollX: 'none' }} // Ensure the Droppable container is scrollable
                                        >
                                            <DataGrid
                                                rows={policyType.map((row, index) => ({
                                                    ...row,
                                                    index,
                                                }))}
                                                columns={[
                                                    ...columns,

                                                ]}
                                                loading={loading}
                                                components={{
                                                    Toolbar: () =>
                                                        CustomToolbar({
                                                            selectedRowIds,
                                                            fetchdata,
                                                        }),
                                                }}
                                                checkboxSelection
                                                onRowSelectionModelChange={
                                                    handleSelectionChange
                                                }
                                                rowSelectionModel={
                                                    selectedRowIds
                                                }
                                                
                                                getRowId={(row) => row._id}
                                                disableSelectionOnClick
                                                disableColumnMenu
                                                disableColumnFilter
                                                disableColumnSelector
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card>
                    </Box>
                </TableStyle>
            </Container >
        </>
    );
};

export default PolicyType;
