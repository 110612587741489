import React, { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box, Grid } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../../components/iconify';
import Add from './Add';
import { apidelete, apiget, deleteManyApi, apipost } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle';
import EditArticle from './Edit';
import formatNumber from '../../components/AmountFormatter'


function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = () => {
        setOpendelete(true);
    };

    // delete many api
    const deleteManyPaymentMethod = async (data) => {

        const result = await deleteManyApi('payment-method/deletemany', data);

        if (result && result.status === 200) {
            fetchdata();
            handleCloseDelete();
        }

    };

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && (
                <Button
                    variant="text"
                    sx={{ textTransform: 'capitalize' }}
                    startIcon={<DeleteOutline />}
                    onClick={handleOpenDelete}
                >
                    Delete
                </Button>
            )}
            <DeleteModel
                opendelete={opendelete}
                handleClosedelete={handleCloseDelete}
                deletedata={deleteManyPaymentMethod}
                id={selectedRowIds}
            />
        </GridToolbarContainer>
    );
}

const PaymentMethod = () => {
    const [userAction, setUserAction] = useState(null);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [paymentMethodData, setPaymentMethodData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState("");
    const [paidAmount, setPaidAmount] = useState([]);
    const [calculatedAmount, setCalculatedAmount] = useState([]);
    const [methodName, setMethodName] = useState([]);
    const [opendelete, setOpendelete] = useState(false);
    const [loading, setLoading] = useState(false);

    const [deleteId, setDeleteID] = useState(null);

    const handleCloseDelete = () => {
        setOpendelete(false);
    };

    const handleOpenDelete = () => {
        setOpendelete(true);
    };


    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole");

    const handleOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };

    // file delete api
    const deleteFile = async (id) => {
        const result = await apidelete(`payment-method/delete/${id}`);
        fetchdata();
        setUserAction(result);
    };

    const editPaymentMethodData = async (id) => {
        try {
            const result = await apiget(`payment-method/view/${id}`);
            if (result && result.status === 200) {
                setPaymentMethodData(result?.data);
            }
        } catch (error) {
            console.error("Error fetching Payment Method data:", error);
        }
    };

    const updatePaymentMethodOrder = async (data) => {
        const result = await apipost('payment-method/update-order', { paymentMethod: data });
        if (result && result.status === 200) {
            setUserAction(result);
        }
    };


    const deleteManyPaymentMethod = async (data) => {

        const result = await deleteManyApi('payment-method/deletemany', data);

        if (result && result.status === 200) {
            fetchdata();
            handleCloseDelete();
        }

    };

    const fetchTotalPremiumAmount = async (method) => {
        try {
            const result = await apiget(`payment-method/calculate-amount?method=${[method]}`);
            if (result && result.status === 200) {
                setCalculatedAmount(result.data)
            }
        } catch (error) {
            console.error('Error fetching total premium amount:', error);
        }

    }

    const fetchTotalPaidAmount = async (method) => {

        try {
            const result = await apiget(`recieved-payment/calculate-paid-amount?method=${[method]}`);
            if (result && result.status === 200) {
                setPaidAmount(result?.data);
            }
        } catch (error) {
            console.error('Error fetching total premium amount:', error);
        }

    }




    const columns = [
        {
            field: 'drag',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                <Draggable draggableId={`${params.row._id}`} index={params.row.index}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Iconify icon="mdi:drag" />
                        </div>
                    )}
                </Draggable>
            ),
        },
        {
            field: "paymentMethod",
            headerName: "Payment Method",
            flex: 1,
        },
        {
            field: 'paymentPaid',
            headerName: "Payment Paid (₹)",
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => {


                const method = params.row.paymentMethod;
                const calculatedItem = calculatedAmount.find(item => item._id === method);
                const amount = calculatedItem ? calculatedItem.totalPremium : 0; // Handle case where no match is found

                return (
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item>
                            {amount !== 0 ? formatNumber(amount) : '--'}
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'paymentRecieved',
            headerName: "Payment Recieved (₹)",
            flex: 1,
            headerAlign: 'center',

            renderCell: (params) => {


                const method = params.row.paymentMethod;
                const calculatedItem = paidAmount.find(item => item._id === method);

                const amount = calculatedItem ? calculatedItem.totalAmount : 0; // Handle case where no match is found

                return (
                    <Grid container justifyContent="center" spacing={1}>
                        <Grid item>
                            {amount !== 0 ? formatNumber(amount) : '--'}
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,

            minWidth: 150,
            maxWidth: 150,
            renderCell: (params) => {
                const handleOpenEditDialog = async (data) => {
                    setOpenEdit(true);
                    editPaymentMethodData(data);
                    setEditId(data);
                };

                return (
                    <Stack direction={"row"} spacing={1} justifyContent="center">
                        <EditIcon
                            variant='text'
                            size='small'
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        />
                        <DeleteIcon
                            variant='outlined'
                            size='small'
                            color='error'
                            onClick={() => {
                                handleOpenDelete();
                                setDeleteID(params.row._id);
                            }
                            }
                        />

                    </Stack>
                );
            },
        },
    ];

    const fetchdata = async () => {
        setLoading(true);
        const result = await apiget(userRole === "admin" ? `payment-method/list` : `payment-method/list/?createdBy=${userid}`);
        if (result && result.status === 200) {
            const paymentMethod = result.data.result.sort((a, b) => a.order - b.order);

            setPaymentMethodList(paymentMethod);
            const methods = paymentMethod.map(item => item.paymentMethod);
            const uniqueMethods = Array.from(new Set(methods)); // Remove duplicates
            setMethodName(uniqueMethods);

        }
        setLoading(false);
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        const reorderedRows = Array.from(paymentMethodList);
        const [removed] = reorderedRows.splice(result.source.index, 1);
        reorderedRows.splice(result.destination.index, 0, removed);

        setPaymentMethodList(reorderedRows);

        // Prepare data to send to the backend
        const orderData = reorderedRows.map((row, index) => ({
            _id: row._id,
            order: index + 1,
        }));

        // Update the order in the backend
        await updatePaymentMethodOrder(orderData);
    };





    useEffect(() => {
        // Call fetchdata when the component mounts or when userAction changes

        fetchdata();
        // Only call the premium and paid amount functions if methodName has at least one method and if userAction meets specific criteria
        if (methodName.length > 0) {
            fetchTotalPremiumAmount(methodName);
            fetchTotalPaidAmount(methodName);
        }

    }, []);


    return (
        <>
            <DeleteModel
                opendelete={opendelete}
                handleClosedelete={handleCloseDelete}
                deletedata={deleteManyPaymentMethod}
                id={[deleteId]}
            />
            <Add open={openAdd} setOpen={setOpenAdd} fetchdata={fetchdata} setUserAction={setUserAction} />
            <EditArticle
                open={openEdit}
                fetchdata={fetchdata}
                singlePaymentMethodData={paymentMethodData}
                handleClose={handleCloseEdit}
                setUserAction={setUserAction}
                id={editId}
            />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">Payment Method</Typography>
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleOpenAdd}
                    >
                        Method
                    </Button>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            style={{ height: '100%', overflow: 'hidden' }}
                                        >
                                            <DataGrid
                                                rows={paymentMethodList.map((row, index) => ({
                                                    ...row,
                                                    index,
                                                }))}
                                                loading={loading}
                                                columns={columns}
                                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                                checkboxSelection
                                                onRowSelectionModelChange={handleSelectionChange}
                                                rowSelectionModel={selectedRowIds}
                                                getRowId={row => row._id}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
};

export default PaymentMethod;
