/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography, TextField } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import PolicyIcon from '@mui/icons-material/Policy';
import AddIcon from "@mui/icons-material/Add";
import TableStyleTwo from "../../components/TableStyleTwo";
import AddPolicy from '../policy/Add'

const Policy = ({ rows, toggleVisibilityPolicy, isVisiblePolicy, _id, contact, familyData, setUserAction }) => {
  const userRole = localStorage.getItem('userRole');
  const [openAdd, setOpenAdd] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [searchText, setSearchText] = useState(''); // Search input state
  const [filteredRows, setFilteredRows] = useState(rows); // Filtered rows state
  const [loading, setLoading] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const navigate = useNavigate();

  
  useEffect(() => {
    if (!rows || !Array.isArray(rows)) return; 

    setLoading(true);

    const filteredData = rows.filter((row) => {
      const insuredPersonName = row.insuredPersonName?.toLowerCase() || '';
      const policyHolder = row.policyHolder?.toLowerCase() || '';
      const additionalInsuredName = row.additionalInsureds?.[0]?.additionalInsuredPersonName?.toLowerCase() || '';

      // Return true if any field starts with searchText
      return (
        insuredPersonName.startsWith(searchText.toLowerCase()) ||
        policyHolder.startsWith(searchText.toLowerCase()) ||
        additionalInsuredName.startsWith(searchText.toLowerCase())
      );
    });

    setFilteredRows(filteredData);
    setLoading(false);
  }, [searchText, rows]);

  // Sort rows by insuredPersonName or policyHolder
  useEffect(() => {
    if (!rows || !Array.isArray(rows)) return; // Safeguard against invalid data

    const sortedData = [...rows].sort((a, b) => {
      const nameA = a.insuredPersonName?.toLowerCase() || a.policyHolder?.toLowerCase() || '';
      const nameB = b.insuredPersonName?.toLowerCase() || b.policyHolder?.toLowerCase() || '';

      return nameA.localeCompare(nameB);
    });

    setFilteredRows(sortedData);
  }, [rows]);

  useEffect(() => {
    const validFamilyData = Array.isArray(familyData) && familyData.length > 0 ? familyData : [];
    const validContact = contact && contact._id ? [contact] : [];

    const combinedData = [...validFamilyData, ...validContact];
    const uniqueData = Array.from(new Set(combinedData.map(item => item._id)))
      .map(id => combinedData.find(item => item._id === id));

    setDropDownData(uniqueData);
  }, [contact, familyData]);

  const columns = [
    {
      field: 'insuredPersonName',
      headerName: 'Proposer Name',
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`);
        };
        return (
          <Grid key={`insured-${params.row._id}`} onClick={handleFirstNameClick} >
            {params?.row?.policyHolder || params?.row?.insuredPersonName}
          </Grid>
        );
      }
    },
    {
      field: '',
      headerName: 'Insured Person Name',
      flex: 1,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      minWidth: 200,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`);
        };
        return (
          <Grid key={`insuredPerson-${params.row._id}`} onClick={handleFirstNameClick} >
            {params?.row?.additionalInsureds?.[0]?.additionalInsuredPersonName || params?.row?.vehicleNumber?.toUpperCase()}
          </Grid>
        );
      }
    },
    {
      field: "policyStartDate",
      headerName: "Policy Start Date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const value = params.value;
        const date = value ? new Date(value) : null;
        if (date && !Number.isNaN(date.getTime())) {
          return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
        }
        return '--';
      },
    },
    {
      field: "policyEndDate",
      headerName: "Policy End Date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const value = params.value;
        const date = value ? new Date(value) : null;
        if (date && !Number.isNaN(date.getTime())) {
          return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
        }
        return '--';
      },
    },
    {
      field: 'vehicleModel',
      headerName: 'Vehicle Model',
      flex: 1,
      headerAlign: 'center',
      minWidth: 200,
      renderCell: (params) => (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          {params?.row?.vehicleModel || '--'}
        </Grid>
      )
    },
    {
      field: "policyType",
      headerName: "Policy Type",
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'policyCompanyName',
      headerName: 'Policy Company',
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'policySource',
      headerName: 'Source',
      flex: 1,
      headerAlign: 'center',
      minWidth: 200,
      renderCell: (params) => (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          {params?.row?.policySource}
        </Grid>
      )
    },
    {
      field: "policyStatus",
      headerName: "Policy Status",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`)
        };

        return (
          <Stack direction={'row'} >
            <Button variant='text' size='small' color='primary' onClick={handleFirstNameClick}>
              <VisibilityIcon />
            </Button>
          </Stack>
        );
      }
    },
  ];

  return (
    <>
      <AddPolicy open={openAdd} handleClose={handleCloseAdd} _id={_id} setUserAction={setUserAction} allPolicyData={dropDownData} />

      <Box style={{ cursor: "pointer" }} p={2}>
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Button
                onClick={toggleVisibilityPolicy}
                color="secondary"
                variant="contained"
                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
              >
                {isVisiblePolicy ? <RemoveIcon /> : <AddIcon />}
              </Button>
              <PolicyIcon />
              <Typography variant="h5">Policy</Typography>

              {isVisiblePolicy && <TextField
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search..."
                variant="outlined"
                size="small"
                style={{ marginBottom: '10px' }}
              />}
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
              {isVisiblePolicy && userRole === 'admin' && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAdd}
                >
                  Create Policy
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Box >
      {
        isVisiblePolicy &&
        <TableStyleTwo>
          <Box width="100%" height="30vh">
            <DataGrid
              rows={filteredRows}
              columns={columns}
              getRowId={row => row._id}
              columnHeaderHeight={40}
              loading={loading}
            />
          </Box>
        </TableStyleTwo>
      }
    </>
  );
};

export default Policy;
