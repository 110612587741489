/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
// @mui
import { Stack, Button, Typography, Box, Grid ,TextField} from '@mui/material';
// components
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import { Download, Share, Delete } from '@mui/icons-material';
import RemoveIcon from "@mui/icons-material/Remove";
import AttachmentIcon from '@mui/icons-material/Attachment';

// sections
import AddDocument from './Add'
import EditDocument from './Edit'
import {deleteManyApi, apiget } from '../../../service/api';
import TableStyleTwo from '../../../components/TableStyleTwo';
import DeleteModel from '../../../components/Deletemodle'
import { constant } from '../../../constant';
import View from '../../HelpCentreManagement/view'
// ----------------------------------------------------------------------

const SurgeryDocumentPage = ({ rows, toggleVisibilitySurgeryDoc, fetchdata, isVisibleSurgeryDoc, setUserAction, _id }) => {


    const userRole = localStorage.getItem("userRole");
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [surgeryViewData, setSurgeryViewData] = useState({});
    const [openDelete, setOpenDelete] = useState(false);

    const [openView, setOpenView] = useState(false);
    const handleOpenView = () => setOpenView(true);


    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);
    const [selectedId,setSelectedId]=useState(null)

    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);
    const [searchText, setSearchText] = useState(''); // Search input state
    const [filteredRows, setFilteredRows] = useState(rows); // Filtered rows state
    const [loading, setLoading] = useState(false);


    const deleteMany = async (deleteId) => {
        const result = await deleteManyApi('surgery-documents/deletemany', deleteId)
        if (result && result.status === 200) {
            setUserAction(result);
            handleCloseDelete();

        }
    }

    const downloadFile = async (id) => {
        if (id) {
            const result = await apiget(`surgery-documents/file/${id}`)
            setUserAction(result)
        }
    }


   

    const fetchSurgeryDocument = async (id) => {
        const result = await apiget(`surgery-documents/view/${id}`);
        if (result && result.status === 200) {
            setSurgeryViewData(result?.data?.result)
        }
    }

    const EditFileName = async (id) => {
        fetchSurgeryDocument(id)
        handleOpenEdit();

    }

    useEffect(() => {
        if (searchText.trim() === '') {
            // Create a copy of rows before sorting to avoid modifying the original array
            const sortedData = [...(rows ?? [])].sort((a, b) => {
                const nameA = a?.surgeryPersonName?.toLowerCase() || '';
                const nameB = b?.surgeryPersonName?.toLowerCase() || '';

                return nameA.localeCompare(nameB);
            });

            setFilteredRows(sortedData); // Set the sorted data to filteredRows
        } else {
            const filteredData = rows && rows.filter((row) => {
                setLoading(true);

                return (
                    row.surgeryPersonName?.toLowerCase().startsWith(searchText.toLowerCase())
                );
            });

            setFilteredRows(filteredData);
            setLoading(false);
        }
    }, [searchText, rows]);

    const columns = [
        {
            field: "file",
            headerName: "File",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {

                const ViewPolicyDocument = () => {
                    fetchSurgeryDocument(params.row._id)
                    handleOpenView();
                }

                return (
                    <Box onClick={ViewPolicyDocument}>
                        {params.value}
                    </Box>
                )
            }
        },
        {
            field: "surgeryPersonName",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "hospitalName",
            headerName: "Hospital",
            flex: 1,
        },

        {
            field: "surgeryName",
            headerName: "Surgery Name",
            flex: 1,
            cellClassName: "name-column--cell--capitalize"
        },
        {
            field: "dateOfSurgery",
            headerName: "Date of Surgery",
            flex: 1,
            valueFormatter: (params) => {
                const value = params.value;
                const date = value ? new Date(value) : null;
                if (date && !Number.isNaN(date.getTime())) {
                    return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
                }
                return '--';
            },
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const handleFirstNameClick = async () => { downloadFile(params.row._id) };
                const downloadUrl = `${constant.baseUrl}surgery-documents/file/${params.row._id}`;

                const handleShareClick = () => {
                    const message = `Click this link to Download Surgery Document : ${downloadUrl}`;
                    const encodedMessage = encodeURIComponent(message);
                    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
                    window.open(whatsappUrl, '_blank');
                };

                const deleteRowData=(id)=>{
                    setSelectedId(id);
                    handleOpenDelete();
                  }

                return (

                    <Stack direction={"row"} spacing={2} alignItems={'center'} >
                        <a href={downloadUrl}><Download variant='contained' sx={{ cursor: 'pointer' }} size='small' onClick={handleFirstNameClick} /></a>
                        {userRole === 'admin' && <Delete variant='outlined' size='small' sx={{ cursor: 'pointer' }}
                            color='error' onClick={()=>deleteRowData(params.row._id)} />}
                       
                        {userRole === 'admin' && <EditIcon variant='outlined' size='small' sx={{ cursor: 'pointer' }}
                            color='primary' onClick={() => EditFileName(params.row._id)} />
                        }
                        <Share variant='outlined' size='small' sx={{ cursor: 'pointer' }} color='primary'
                            onClick={handleShareClick}
                        />
                    </Stack>

                );
            }
        },

    ];

    return (
        <>
            {/* Add Document Model */}
            <DeleteModel opendelete={openDelete} handleClosedelete={handleCloseDelete} deletedata={deleteMany} id={[selectedId]} />
            <View open={openView} setOpen={setOpenView} setUserAction={setUserAction} data={surgeryViewData} header="Surgery Document" imageFolder="surgery_documents" />
            <AddDocument open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />
            <EditDocument open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} _id={_id} data={surgeryViewData} />
            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilitySurgeryDoc}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisibleSurgeryDoc ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">Surgery Documents</Typography>
                            {isVisibleSurgeryDoc && <TextField
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search..."
                                variant="outlined"
                                size="small"
                                style={{ marginBottom: '10px' }}
                            />}
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisibleSurgeryDoc && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Add Surgery Document
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisibleSurgeryDoc &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                            loading={loading}
                        />
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default SurgeryDocumentPage