import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box, Rating, Grid, Switch } from '@mui/material';
// components
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import Iconify from '../../components/iconify';
import AddTestimonial from './Add'
import { apidelete, apiget, apipost, apiput, deleteManyApi } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle'
import EditTestimonial from './Edit'
import View from '../HelpCentreManagement/view'
import Palette from '../../theme/palette'


function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
        await deleteManyApi('testimonial/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const Testimonial = () => {
    const [userAction, setUserAction] = useState(null);
    const [TestimonialList, setTestimonialList] = useState([]);
    const [userTestimonial, setUserTestimonial] = useState([]);
    const data = userTestimonial;
    const [TestimonialData, setTestimonialData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState("");
    const [contactId, setContactId] = useState(null);
    const [showControls, setShowControls] = useState(false);
    const [loading,setLoading]=useState(false);



    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")
    const UserData = localStorage.getItem('user');
    const parsedHello = JSON.parse(UserData);
    const emailAddress = parsedHello.emailAddress || '';


    const handleOpenAdd = () => {
        setOpenAdd(true)
    }
    const handleOpenView = () => {
        setOpenView(true);
    }
    const handleCloseAdd = () => {
        setOpenAdd(false)
    }
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };


    // file delete api
    const deleteFile = async (id) => {
        const result = await apidelete(`testimonial/delete/${id}`)
        setUserAction(result)
    }
    const editTestimonialData = async (id) => {

        try {
            const result = await apiget(`testimonial/view/${id}`);

            if (result && result.status === 200) {
                setTestimonialData(result?.data?.testimonial);
            }
        } catch (error) {
            console.error("Error fetching Testimonial data:", error);
        }
    };



    const columns = [
        // {
        //     field: "name",
        //     headerName: "Name",
        //     flex: 1,
        // },

        {
            field: "title",
            headerName: "Title",
            flex: 1,

        },
        {
            field: "content",
            headerName: "Content",
            flex: 1,

        },
        {
            field: 'rating',
            headerName: 'Rating',
            headerAlign: 'center',
            flex: 0,
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0,
            renderCell: (params) => (

                <Switch
                    checked={params.row.status === 'On'}
                    onChange={(event) => {
                        const newValue = event.target.checked ? 'On' : 'Off';
                        updateStatus(params.row._id, newValue);
                    }}
                    name="status"
                    color="primary"
                />
            ),
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            minWidth: 150,
            maxWidth: 150,
            renderCell: (params) => {

                const handleOpenEditDialog = async (data) => {
                    setOpenEdit(true);
                    editTestimonialData(data);
                    setEditId(data)
                }

                return (

                    <Stack direction={"row"} spacing={3}>
                        <EditIcon
                            variant='text'
                            sx={{ cursor: 'pointer' }}
                            size='small'
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)}
                        />
                        <DeleteIcon variant='outlined' size='small' color='error' sx={{ cursor: 'pointer' }}
                            onClick={() => deleteFile(params.row._id)} />
                        <Visibility variant='outlined' size='small' color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
                            editTestimonialData(params.row._id);
                            handleOpenView();
                        }} />
                    </Stack>

                );
            }
        },
    ];

    const fetchdata = async () => {
        
        try {

            const result = await apiget(`testimonial/list/?contact_id=${contactId}`);

            if (result && result.status === 200) {
                setUserTestimonial(result?.data?.result[0]);
            }
        } catch (error) {
            console.error("Failed to fetch testimonial data:", error);
        }
    }

    const fetchAdminData = async () => {
        setLoading(true)
        try {

            const result = await apiget(`testimonial/list`);

            if (result && result.status === 200) {
                setTestimonialList(result?.data?.result);
            }
        } catch (error) {
            console.error("Failed to fetch testimonial data:", error);
        }
        setLoading(false)
    }

    const updateStatus = async (statusId, value) => {
        try {
            const result = await apiput(`testimonial/status/${statusId}`, { status: value });

            if (result && result.status === 200) {
                fetchAdminData();
            }

        } catch (error) {
            console.error('Error updating status:', error.message);
        }
    };

    useEffect(() => {
        if (contactId) {
            fetchdata();
        }
    }, [contactId, userAction]);


    useEffect(() => {
        fetchAdminData();
    }, [userAction])

    useEffect(() => {
        const fetchContactId = async () => {
            try {

                if (userRole === 'admin') return;
                const contactData = await apiget(`contact/user-contact?email=${emailAddress}`);

                if (contactData?.data?.result?.length > 0) {
                    const searchID = contactData.data.result[0]._id;
                    if (searchID) {
                        setContactId(searchID);
                        setShowControls(true);
                        return;
                    }
                }

                // If no contact ID found, fallback to family contact data
                const familyContactData = await apiget(`contact-relation/user-email?email=${emailAddress}`);

                const familyContactId = familyContactData?.data?.result?.contact_id;
                if (familyContactId) {
                    setContactId(familyContactId);

                }

            } catch (error) {
                console.error("Failed to fetch contact ID:", error);
            }
        };

        fetchContactId();
    }, [emailAddress]);



    return (
        <>
            {/* Add Testimonial Model */}
            <View open={openView} _id={contactId} setOpen={setOpenView} setUserAction={setUserAction} data={TestimonialData} header="Testimonial" imageFolder="testimonials" />
            <AddTestimonial _id={contactId} open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <EditTestimonial _id={contactId} open={openEdit} singleTestimonialData={TestimonialData} editTestimonialData={editTestimonialData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Testimonials
                    </Typography>
                    {userRole === 'user' && showControls && !data && (
                        <Button
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                            onClick={handleOpenAdd}
                        >
                            New Testimonial
                        </Button>
                    )}

                   {userRole==='admin' && <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={handleOpenAdd}
                    >
                        New Testimonial
                    </Button>}

                    {userRole === 'user' && showControls && data && <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {
                        setOpenEdit(true);
                        setEditId(data?._id);
                        editTestimonialData(data?._id);
                    }}>
                        Edit
                    </Button>}
                </Stack>
                {userRole === 'admin' && <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={TestimonialList}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                                loading={loading}
                            />
                        </Card>
                    </Box>
                </TableStyle>}

                {/* userrView */}

                {userRole === 'user' && <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                    <Box p={3}>
                        <Grid container display="flex" spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1">Title :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{data?.title ? data?.title : '--'}</Typography>
                                </Grid>

                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                                    <Typography variant="body1">Rating :</Typography>
                                    {data?.rating ? <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>
                                        <Rating
                                            name="rating"
                                            value={data?.rating ? data?.rating : 0}
                                            precision={0.5}  // Allows half-star ratings
                                            readOnly         // Makes the stars non-interactive
                                        />
                                    </Typography> : '--'}
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1">Descirption :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{data?.content ? data?.content : '--'}</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Card>}
            </Container>
        </>
    );
}

export default Testimonial