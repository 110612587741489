/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, MenuItem, FormLabel, FormHelperText, FormControl, Select,InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import { apipost } from "../../../service/api";
import formatNumber from '../../../components/AmountFormatter'


const Add = (props) => {
    const { open, handleClose, setUserAction, _id } = props
    const userid = localStorage.getItem('user_id')

    // -----------  validationSchema
    const validationSchema = yup.object({
        dueDate: yup.date().required("Due Date is required"),
        dueAmount: yup.string().required("Due Amount is required"),
        status: yup.string().required("Status is required"),
        desc: yup.string(),
    });

    // -----------   initialValues
    const initialValues = {
        dueDate: "",
        dueAmount: "",
        desc: "",
        status: "Pending",
        createdBy: userid,
        policy_id: _id
    };

    // add contact api
    const fileUpload = async (values) => {

        const result = await apipost('payment-plan/add', values)


        if (result && result.status === 200) {
            setUserAction(result)
            formik.resetForm();
            handleClose();

        }
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const dueAmount=values.dueAmount.replace(/,/g, '');

            const updatedValues={
                ...values,
                dueAmount: Number(dueAmount) || 0
            }
            fileUpload(updatedValues)
        },
    });

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                onClose={() => {
                    handleClose();
                    formik.resetForm();
                }}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add New Plan</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={() => {
                                handleClose();
                                formik.resetForm();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form encType="multipart/form-data">
                        <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                        >

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Due Amount<Typography component={'span'} color={'red'}>*</Typography></FormLabel>
                                <TextField
                                    id="dueAmount"
                                    name="dueAmount"
                                    size="small"
                                    type="text"
                                    fullWidth
                                    value={formik.values.dueAmount}
                                    onChange={(e) => {
                                        const formattedValue = formatNumber(e.target.value); // Format the input value
                                        formik.setFieldValue('dueAmount', formattedValue); // Update Formik state
                                    }}
                                    error={
                                        formik.touched.dueAmount && Boolean(formik.errors.dueAmount)
                                    }
                                    helperText={formik.touched.dueAmount && formik.errors.dueAmount}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>Due Date<Typography component={'span'} color={'red'}>*</Typography></FormLabel>
                                <TextField
                                    id="dueDate"
                                    name="dueDate"
                                    size="small"
                                    type="date"
                                    fullWidth
                                    value={formik.values.dueDate}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.dueDate && Boolean(formik.errors.dueDate)
                                    }
                                    helperText={formik.touched.dueDate && formik.errors.dueDate}
                                    InputProps={{ onKeyDown: (e) => e.preventDefault() }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormLabel>
                                    Payment Status<Typography component={'span'} color={'red'}>*</Typography>
                                </FormLabel>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    error={formik.touched.status && Boolean(formik.errors.status)}
                                >
                                    <Select
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} // To trigger the touched state
                                    >
                                        <MenuItem key={'pending'} value={'Pending'}>
                                            Pending
                                        </MenuItem>
                                        <MenuItem key={'paid'} value={'Paid'}>
                                            Paid
                                        </MenuItem>
                                    </Select>

                                    {/* Show helper text if there is an error */}
                                    {formik.touched.status && formik.errors.status && (
                                        <FormHelperText>{formik.errors.status}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormLabel>Description</FormLabel>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.desc && Boolean(formik.errors.desc)
                                    }
                                    helperText={formik.touched.desc && formik.errors.desc}
                                />
                            </Grid>

                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{ textTransform: "capitalize" }}
                    // startIcon={<FiSave />}
                    >
                        Save
                    </Button>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="error"
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Add