import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// components
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../../components/iconify';
import Add from './Add'
import { apidelete, apiget, deleteManyApi } from '../../service/api';
import TableStyle from '../../components/TableStyle';
import DeleteModel from '../../components/Deletemodle'
import Edit from './Edit'



function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
        await deleteManyApi('referral-source/deletemany', data)
        fetchdata()
        handleCloseDelete();
    }

    return (
        <GridToolbarContainer>
            <GridToolbar />
            {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>}
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
        </GridToolbarContainer>
    );
}

const ReferralSource = () => {
    const [userAction, setUserAction] = useState(null);
    const [ReferralSourceList, setReferralSourceList] = useState([]);
    const [ReferralSourceData, setReferralSourceData] = useState({});
    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [editId, setEditId] = useState("");
    const [deleteId, setDeleteID] = useState(null);

    const userid = localStorage.getItem('user_id');
    const userRole = localStorage.getItem("userRole")
    const [opendelete, setOpendelete] = useState(false);


    const handleCloseDelete = () => {
        setOpendelete(false)
    }

    const handleOpenDelete = () => {
        setOpendelete(true)
    }

    // delete many api
    const deleteManyContact = async (data) => {
        const result = await deleteManyApi('referral-source/deletemany', data)
        if (result && result.status === 200) {
            fetchdata()
            handleCloseDelete();
        }

    }

    const handleOpenAdd = () => {
        setOpenAdd(true)
    }
    const handleCloseAdd = () => {
        setOpenAdd(false)
    }
    const handleCloseEdit = () => {
        setOpenEdit(false);
    }

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };


    // file delete api
    const deleteFile = async (id) => {
        const result = await apidelete(`referral-source/delete/${id}`)
        setUserAction(result)
    }
    const editReferralSourceData = async (id) => {
        try {
            const result = await apiget(`referral-source/view/${id}`);
            if (result && result.status === 200) {

                setReferralSourceData(result?.data);

            }
        } catch (error) {
            console.error("Error fetching ReferralSource data:", error);
        }
    };

    const columns = [
        {
            field: "referralSource",
            headerName: "Referral Source",
            flex: 1,
        },


        {
            field: "sourceType",
            headerName: "Referral Source Type",
            flex: 1,
            headerAlign: "center",
            renderCell: (params) => (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {params.value}
                </Box>
            ),
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            maxWidth: 150,
            minWidth: 150,
            renderCell: (params) => {

                const handleOpenEditDialog = async (data) => {
                    setOpenEdit(true);
                    editReferralSourceData(data);
                    setEditId(data)
                }

                return (

                    <Stack direction={"row"} spacing={3}>
                        <EditIcon
                            variant='outlined'
                            size='small'
                            sx={{ cursor: 'pointer' }}
                            color='primary'
                            onClick={() => handleOpenEditDialog(params?.row?._id)} />

                        <DeleteIcon sx={{ cursor: 'pointer' }} variant='outlined' size='small' color='error' onClick={() => {
                            handleOpenDelete();
                            setDeleteID(params.row._id);
                        }
                        } />

                    </Stack>

                );
            }
        },

    ];

    // fetch ReferralSources list
    const fetchdata = async () => {
        setLoading(true)
        const result = await apiget(userRole === "admin" ? `referral-source/list` : `referral-source/list/?createdBy=${userid}`)
        if (result && result.status === 200) {

            const sortedData = result?.data?.result.sort((a, b) => {
                const nameA = a.referralSource || '';
                const nameB = b.referralSource || '';
                return nameA.localeCompare(nameB);
            });

            setReferralSourceList(sortedData)
        }
        setLoading(false)
    }



    useEffect(() => {
        fetchdata();
    }, [userAction])
    return (
        <>
            <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={[deleteId]} />
            <Add open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />
            <Edit open={openEdit} singleReferralSourceData={ReferralSourceData} handleClose={handleCloseEdit} setUserAction={setUserAction} id={editId} />
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4">
                        Referral Source
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                        Referral Source
                    </Button>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        <Card style={{ height: "600px", paddingTop: "15px" }}>
                            <DataGrid
                                rows={ReferralSourceList}
                                columns={columns}
                                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                                checkboxSelection
                                onRowSelectionModelChange={handleSelectionChange}
                                rowSelectionModel={selectedRowIds}
                                getRowId={row => row._id}
                                loading={loading}
                            />
                        </Card>
                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default ReferralSource


